import React from 'react';
import { toTitleCase } from '../../helpers/helpers';

export default function Issue({ type, adClassNames = '' }) {
  const getColor = type => {
    switch (type.toLowerCase()) {
      case 'heating':
        return '#FFE1CC';
      case 'plumbing':
        return '#D0E5FB';
      case 'electrical':
        return '#FFF1BD';
      case 'cooling':
        return '#CDF2D3';
      default:
        return '#FFDEF8';
    }
  };
  return (
    <div
      className={`badge badge-md ${adClassNames}`}
      style={{ backgroundColor: getColor(type), color: '#000' }}
    >
      {toTitleCase(type)}
    </div>
  );
}
