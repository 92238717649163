import React from 'react';
import playIcon from '../../assets/images/play.svg';
import pauseIcon from '../../assets/images/pause-circle.svg';

import useAudio from '../../hooks/useAudio';

const PlayAudioButton = ({ audioLink }) => {
  const [isPlaying, togglePlay, duration] = useAudio(audioLink);
  return (
    <div className="position-absolute">
      <button
        className="btn p-1 line-height-1"
        onClick={() => {
          togglePlay();
        }}
      >
        {!isPlaying && <img src={playIcon} alt="..." />}
        {isPlaying && <img src={pauseIcon} alt="..." />}
      </button>
    </div>
  );
};

export default PlayAudioButton;
