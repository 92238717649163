import logoWithTitle from '../assets/images/shopsupply-logo-with-title.svg';
import playAltIcon from '../assets/images/play-alt-icon.svg';
import audioWaveIcon from '../assets/images/audio-waves.svg';
import backButton from '../assets/images/angle-left.svg';
import pauseIconAlt from '../assets/images/pause-icon-alt.svg';
import playIconAlt from '../assets/images/play-alt-icon.svg';
import moreIcon from '../assets/images/more.svg';
import sortIcon from '../assets/images/icon-sort.png';
import sortDownIcon from '../assets/images/icon-sort-down.png';
import sortUpIcon from '../assets/images/icon-sort-up.png';

export const icons = {
  logoWithTitle,
  playAltIcon,
  audioWaveIcon,
  backButton,
  pauseIconAlt,
  playIconAlt,
  moreIcon,
  sortIcon,
  sortDownIcon,
  sortUpIcon,
};
