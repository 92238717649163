import React, {useCallback, useEffect, useState} from 'react';
import {Dropdown, Modal} from 'react-bootstrap';
import MultiSelect from 'react-multiple-select-dropdown-lite';
import {REQUEST_STATUS} from '../../constants/loading';
import {createProperty, editProperty, fetchPropertyUsers} from '../../services/dashBoardServices';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const bussinesHoursInitial = [
  {
    day: 'Monday',
    startHour: 9,
    startPeriod: 'am',
    closeHour: 5,
    closePeriod: 'pm',
    isOpen: true,
  },
  {
    day: 'Tuesday',
    startHour: 9,
    startPeriod: 'am',
    closeHour: 5,
    closePeriod: 'pm',
    isOpen: true,
  },
  {
    day: 'Wednesday',
    startHour: 9,
    startPeriod: 'am',
    closeHour: 5,
    closePeriod: 'pm',
    isOpen: true,
  },
  {
    day: 'Thursday',
    startHour: 9,
    startPeriod: 'am',
    closeHour: 5,
    closePeriod: 'pm',
    isOpen: true,
  },
  {
    day: 'Friday',
    startHour: 9,
    startPeriod: 'am',
    closeHour: 5,
    closePeriod: 'pm',
    isOpen: true,
  },
  {
    day: 'Saturday',
    startHour: 9,
    startPeriod: 'am',
    closeHour: 5,
    closePeriod: 'pm',
    isOpen: true,
  },
  {
    day: 'Sunday',
    startHour: 9,
    startPeriod: 'am',
    closeHour: 5,
    closePeriod: 'pm',
    isOpen: true,
  },
];

const errorInitial = {
  propertyName: false,
  amazonConnectNumber: false,
  timeZone: false,
  amazonConnectMaintenanceNumber : false,
  originalPropertyPhoneNumber : false,
};

const propertyDataInitial = () => {
  return JSON.parse(
    JSON.stringify({
      propertyName: '',
      amazonConnectNumber: '',
      amazonConnectMaintenanceNumber: '',
      timeZone: '',
      businessHoursList: [...bussinesHoursInitial],
      lettersInUnit: false,
      resmanPropertyName: '',
      resmanPropertyId: '',
      getdonePropertyName: '',
      getdonePropertyId: '',
      courtesyOfficerUserId: '',
      originalPropertyPhoneNumber: '',
    })
  );
};

const requiredFields = ['propertyName', 'amazonConnectNumber', 'timeZone'];

export default function EditPropertyModal({
  show,
  onHide,
  isEditProperty,
  propertyData,
  resmanPropListOption,
  showToast,
  setLoadingState,
  onlyHide,
  onlyShow,
}) {
  const [errors, setErrors] = useState(errorInitial);
  const [property, setProperty] = useState(propertyDataInitial());
  const [resmanInitial, setResmanInitial] = useState('');
  const [isResmanUnitSynced, setIsResmanUnitSynced] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [courtesyOfficerListOption, setCourtesyOfficerListOption] = useState([]);

  const closePropertyModal = reload => {
    setErrors(errorInitial);
    onHide(reload);
  };

  useEffect(() => {
    if (show) {
      setCourtesyOfficerListOption([]);
      setErrors(errorInitial);
      if (propertyData) {
        setProperty(propertyData);
        setResmanInitial(propertyData?.resmanPropertyId);
        setIsResmanUnitSynced(true);
        getCourtesyOfficerList();
      } else {
        setProperty(propertyDataInitial());
      }
    }
  }, [propertyData, show]);

  const getCourtesyOfficerList = useCallback(async () => {
    const courtesyOfficerList = await fetchPropertyUsers(propertyData?.propertyId);
    const listOptions = courtesyOfficerList?.userList.map(item => ({
      label: item.firstName + ' ' + item.lastName,
      value: item.id,
    }));
    setCourtesyOfficerListOption(listOptions);
  });

  useEffect(() => {
    const onSubmit = async () => {
      let hasError = false;
      Object.keys(errors).map(field => {
        if (errors[field]) {
          hasError = true;
        }
      });

      if (hasError) {
        showToast({ show: true, message: 'Form data invalid!', bg: 'danger' });
      } else {
        setLoadingState(REQUEST_STATUS.LOADING);
        const res = isEditProperty
          ? await editProperty(property?.propertyId, property)
          : await createProperty(property);

        if (res.status !== 200 && res.message) {
          showToast({ show: true, message: res.message, bg: 'danger' });
        }
        setLoadingState(REQUEST_STATUS.SUCCESS);

        closePropertyModal(true);
      }
    };
    if (canSubmit) {
      setCanSubmit(false);
      onSubmit();
    }
  }, [canSubmit]);

  const getTimeZoneLabel = timeZone => {
    let timezoneLabel = '';
    switch (timeZone) {
      case 'us_pacific':
        timezoneLabel = 'US Pacific';
        break;
      case 'us_mountain':
        timezoneLabel = 'US Mountain';
        break;
      case 'us_central':
        timezoneLabel = 'US Central';
        break;
      case 'us_eastern':
        timezoneLabel = 'US Eastern';
        break;
      default:
        timezoneLabel = 'Select Time Zone';
    }
    return timezoneLabel;
  };

  const updateBusinessHours = (val, field, index) => {
    setProperty(prevState => {
      const newState = { ...prevState };
      newState.businessHoursList[index][field] = val;
      return newState;
    });
  };

  const updatePropery = (val, field) => {
    let updateVal = val;
    if (field === 'propertyName') {
      setErrors(prevErrors => {
        return {
          ...prevErrors,
          propertyName: updateVal.length < 1,
        };
      });
    } else if (field === 'amazonConnectNumber') {
      updateVal = val.replace(/[^0-9.]/g, '');
      setErrors(prevErrors => ({
        ...prevErrors,
        amazonConnectNumber: updateVal.length !== 10,
      }));
    } else if (field === 'amazonConnectMaintenanceNumber') {
      if(val !== ''){
        updateVal = val.replace(/[^0-9.]/g, '');
        setErrors(prevErrors => ({
          ...prevErrors,
          amazonConnectMaintenanceNumber: updateVal.length !== 10,
        }));
      }
    } else if (field === 'originalPropertyPhoneNumber') {
      if(val !== ''){
        updateVal = val.replace(/[^0-9.]/g, '');
        setErrors(prevErrors => ({
          ...prevErrors,
          originalPropertyPhoneNumber: updateVal.length !== 10,
        }));
      }
    } else if (field === 'timeZone') {
      setErrors(prevErrors => {
        return { ...prevErrors, timeZone: !updateVal };
      });
    }

    setProperty(prevProperty => {
      return {
        ...prevProperty,
        [field]: updateVal,
      };
    });
  };

  const handleResmanProperty = val => {
    let selectedResman = resmanPropListOption.find(item => {
      return item.value === val;
    });

    if (!selectedResman) {
      selectedResman = {
        value: '',
        label: '',
      };
    }

    setProperty(prevState => {
      return {
        ...prevState,
        resmanPropertyId: selectedResman.value,
        resmanPropertyName: selectedResman.label,
      };
    });
    if (val === resmanInitial) {
      setResmanInitial(resmanInitial);
      setIsResmanUnitSynced(true);
    } else {
      setIsResmanUnitSynced(false);
    }
  };

  const handleCourtesyOfficer = val => {
    let selectedCourtesyOfficer = courtesyOfficerListOption.find(item => {
      return item.value === val;
    });

    if (!selectedCourtesyOfficer) {
      selectedCourtesyOfficer = {
        value: '',
        label: '',
      };
    }

    setProperty(prevState => {
      return {
        ...prevState,
        courtesyOfficerUserId: selectedCourtesyOfficer.value,
      };
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    requiredFields.map(field => {
      updatePropery(property[field], field);
    });

    setCanSubmit(true);
  };

  const [syncUnitsModal, setSyncUnitsModal] = useState(false);

  const openSyncConfirmation = e => {
    e.preventDefault();
    onlyHide();
    setSyncUnitsModal(true);
  };
  const closeSyncConfirmation = () => {
    setSyncUnitsModal(false);
  };

  return (
    <>
      <Modal show={show} onHide={onHide} centered size={'lg'}>
        <Modal.Header>
          <h3 className="font-weight-bold mb-0">
            {isEditProperty ? 'Edit Property' : 'New Property'}
          </h3>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={event => handleSubmit(event)}>
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="mb-4">
                  <label htmlFor="property-Name" className="form-label">
                    Property Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    onChange={e => {
                      updatePropery(e.target.value, 'propertyName');
                    }}
                    id="property-Name"
                    name="propertyName"
                    value={property?.propertyName}
                    className={
                      errors?.propertyName
                        ? 'form-control min-height-46 is-invalid'
                        : 'form-control min-height-46'
                    }
                  />
                  {errors?.propertyName && (
                    <span className="text-danger">Property Name Required</span>
                  )}
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="mb-4">
                  <label htmlFor="amazonNumber" className="form-label">
                    Amazon Connect Emergency Phone Number <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    maxLength="10"
                    value={property?.amazonConnectNumber}
                    onChange={e => updatePropery(e.target.value, 'amazonConnectNumber')}
                    id="amazonNumber"
                    name="amazonConnectNumber"
                    className={
                      errors?.amazonConnectNumber
                        ? 'form-control min-height-46 is-invalid'
                        : 'form-control min-height-46'
                    }
                  />
                  {errors?.amazonConnectNumber && (
                    <span className="text-danger">Amazon Connect Phone Number Required</span>
                  )}
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="mb-4">
                  <label htmlFor="amazonNumber" className="form-label">
                    Amazon Connect Maintenance Phone Number
                  </label>
                  <input
                      type="text"
                      maxLength="10"
                      value={property?.amazonConnectMaintenanceNumber}
                      onChange={e => updatePropery(e.target.value, 'amazonConnectMaintenanceNumber')}
                      id="amazonMaintenanceNumber"
                      name="amazonConnectMaintenanceNumber"
                      className={
                        errors?.amazonConnectMaintenanceNumber
                            ? 'form-control min-height-46 is-invalid'
                            : 'form-control min-height-46'
                      }
                  />
                  {errors?.amazonConnectMaintenanceNumber && (
                      <span className="text-danger">Amazon Connect Maintenance Phone Number Invalid</span>
                  )}
                </div>
              </div>
              <div className="col-md-12 col-12">
                <div className="mb-4">
                  <label htmlFor="time-Zone" className="form-label">
                    Time Zone
                  </label>
                  <Dropdown
                    className={errors?.timeZone ? 'custom-dropdown is-invalid' : 'custom-dropdown'}
                    onSelect={e => updatePropery(e, 'timeZone')}
                  >
                    <Dropdown.Toggle className="outline-toggle" variant="link" id="time-Zone">
                      {getTimeZoneLabel(property?.timeZone)}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="full-width">
                      <Dropdown.Item eventKey="us_pacific">US Pacific</Dropdown.Item>
                      <Dropdown.Item eventKey="us_mountain">US Mountain</Dropdown.Item>
                      <Dropdown.Item eventKey="us_central">US Central</Dropdown.Item>
                      <Dropdown.Item eventKey="us_eastern">US Eastern</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {errors?.timeZone && <span className="text-danger">Time zone required</span>}
                </div>
              </div>
              <div className="col-md-12 col-12">
                <div className="mb-4">
                  <label htmlFor="originalPropertyPhoneNumber" className="form-label">
                    Original Property Phone Number
                  </label>
                  <input
                      type="text"
                      maxLength="10"
                      value={property?.originalPropertyPhoneNumber}
                      onChange={e => updatePropery(e.target.value, 'originalPropertyPhoneNumber')}
                      id="originalPropertyPhoneNumber"
                      name="originalPropertyPhoneNumber"
                      className={
                        errors?.originalPropertyPhoneNumber
                            ? 'form-control min-height-46 is-invalid'
                            : 'form-control min-height-46'
                      }
                  />
                  {errors?.originalPropertyPhoneNumber && (
                      <span className="text-danger">Original Property Phone Number Invalid</span>
                  )}
                </div>
              </div>
              <div className="col-md-6  col-12">
                <div className="mb-4">
                  <label htmlFor="resmanPropertyID" className="form-label">
                    Resman Property
                  </label>
                  <MultiSelect
                    className="custom-multiple-select"
                    onChange={handleResmanProperty}
                    placeholder="Resman Not Used"
                    options={resmanPropListOption}
                    defaultValue={property?.resmanPropertyId}
                    singleSelect
                    closeOnSelect
                  />
                </div>
              </div>
              <div className="col-md-6 col-12">
                <label className="form-label d-block text-white d-none d-md-block">. </label>
                {isEditProperty && (
                  <>
                    <OverlayTrigger
                      placement="top"
                      delay={{
                        show: 250,
                        hide: 400,
                      }}
                      overlay={<Tooltip>To Sync units all details must be saved</Tooltip>}
                    >
                      <button
                        disabled={resmanInitial === '' || !isResmanUnitSynced}
                        className="btn btn-warning btn-width-sm ms-2 mb-4"
                        onClick={e => openSyncConfirmation(e)}
                      >
                        Sync Units
                      </button>
                    </OverlayTrigger>
                  </>
                )}
              </div>
              <div className="col-md-12 col-12">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    id="lettersInUnit"
                    type="checkbox"
                    checked={property?.lettersInUnit}
                    onChange={e => updatePropery(e.target.checked, 'lettersInUnit')}
                  />
                  <label className="form-check-label mb-4 font-size-15" htmlFor="lettersInUnit">
                    This property's unit labels include letters.
                  </label>
                </div>
              </div>
              {courtesyOfficerListOption.length > 0 && (
                  <div className="col-md-6  col-12">
                    <div className="mb-4">
                      <label htmlFor="courtesyOfficerId" className="form-label">
                        Courtesy Officer
                      </label>
                      <MultiSelect
                          className="custom-multiple-select"
                          onChange={handleCourtesyOfficer}
                          placeholder="No Courtesy Officer"
                          options={courtesyOfficerListOption}
                          defaultValue={property?.courtesyOfficerUserId}
                          singleSelect
                          closeOnSelect
                      />
                    </div>
                  </div>
              )}
              <div className="col-12">
                <div className="mb-4">
                  <label className="form-label">
                    Business hours <span className="text-danger">*</span>
                  </label>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <div className="table-responsive mb-0">
                        <table className="table table-sm table-nowrap card-table head-light">
                          <tbody>
                            {property.businessHoursList.map((listItem, index) => {
                              return (
                                <tr key={index}>
                                  <td>{listItem.day}</td>
                                  <td
                                    className={
                                      !property.businessHoursList[index].isOpen
                                        ? 'disable-fields'
                                        : ''
                                    }
                                  >
                                    <div className="d-flex">
                                      <div className="d-flex align-items-center">
                                        <label
                                          htmlFor={`monday${index}`}
                                          className="form-label mb-0 me-2"
                                        >
                                          Open
                                        </label>
                                        <Dropdown
                                          className="custom-dropdown d-flex flex-column width-sm form-control-sm py-0 min-height-46"
                                          onSelect={val =>
                                            updateBusinessHours(val, 'startHour', index)
                                          }
                                        >
                                          <Dropdown.Toggle
                                            className="outline-toggle toggle-sm"
                                            variant="link"
                                            id={`time-zone${index}`}
                                          >
                                            {property.businessHoursList[index].startHour || '--'}
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu
                                            align="start"
                                            flip={true}
                                            className="full-width"
                                          >
                                            <Dropdown.Item eventKey="1">1</Dropdown.Item>
                                            <Dropdown.Item eventKey="2">2</Dropdown.Item>
                                            <Dropdown.Item eventKey="3">3</Dropdown.Item>
                                            <Dropdown.Item eventKey="4">4</Dropdown.Item>
                                            <Dropdown.Item eventKey="5">5</Dropdown.Item>
                                            <Dropdown.Item eventKey="6">6</Dropdown.Item>
                                            <Dropdown.Item eventKey="7">7</Dropdown.Item>
                                            <Dropdown.Item eventKey="8">8</Dropdown.Item>
                                            <Dropdown.Item eventKey="9">9</Dropdown.Item>
                                            <Dropdown.Item eventKey="10">10</Dropdown.Item>
                                            <Dropdown.Item eventKey="11">11</Dropdown.Item>
                                            <Dropdown.Item eventKey="12">12</Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                      <label className="custom-toggle-switch ms-2">
                                        <input
                                          type="checkbox"
                                          className="form-control"
                                          name="startPeriod"
                                          onChange={e =>
                                            updateBusinessHours(
                                              e.target.checked ? 'pm' : 'am',
                                              'startPeriod',
                                              index
                                            )
                                          }
                                          checked={
                                            property.businessHoursList[index].startPeriod === 'pm'
                                              ? true
                                              : false
                                          }
                                        />
                                        <span className="slider round"></span>
                                      </label>
                                    </div>
                                  </td>
                                  <td
                                    className={
                                      !property.businessHoursList[index].isOpen
                                        ? 'disable-fields'
                                        : ''
                                    }
                                  >
                                    <div className="d-flex">
                                      <div className="d-flex align-items-center">
                                        <label className="form-label mb-0 me-2">Close</label>
                                        <Dropdown
                                          className="custom-dropdown d-flex flex-column width-sm form-control-sm py-0 min-height-46"
                                          onSelect={val =>
                                            updateBusinessHours(val, 'closeHour', index)
                                          }
                                        >
                                          <Dropdown.Toggle
                                            className="outline-toggle toggle-sm"
                                            variant="link"
                                            id={`time-zone-Close${index}`}
                                          >
                                            {property.businessHoursList[index].closeHour || '--'}
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu
                                            align="start"
                                            flip={true}
                                            className="full-width"
                                          >
                                            <Dropdown.Item eventKey="1">1</Dropdown.Item>
                                            <Dropdown.Item eventKey="2">2</Dropdown.Item>
                                            <Dropdown.Item eventKey="3">3</Dropdown.Item>
                                            <Dropdown.Item eventKey="4">4</Dropdown.Item>
                                            <Dropdown.Item eventKey="5">5</Dropdown.Item>
                                            <Dropdown.Item eventKey="6">6</Dropdown.Item>
                                            <Dropdown.Item eventKey="7">7</Dropdown.Item>
                                            <Dropdown.Item eventKey="8">8</Dropdown.Item>
                                            <Dropdown.Item eventKey="9">9</Dropdown.Item>
                                            <Dropdown.Item eventKey="10">10</Dropdown.Item>
                                            <Dropdown.Item eventKey="11">11</Dropdown.Item>
                                            <Dropdown.Item eventKey="12">12</Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                      <label className="custom-toggle-switch ms-2">
                                        <input
                                          type="checkbox"
                                          className="form-control"
                                          name="closePeriod"
                                          onChange={e =>
                                            updateBusinessHours(
                                              e.target.checked ? 'pm' : 'am',
                                              'closePeriod',
                                              index
                                            )
                                          }
                                          checked={
                                            property.businessHoursList[index].closePeriod === 'pm'
                                              ? true
                                              : false
                                          }
                                        />
                                        <span className="slider round"></span>
                                      </label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        onChange={e =>
                                          updateBusinessHours(!e.target.checked, 'isOpen', index)
                                        }
                                        value=""
                                        id={`closeDay${index}`}
                                        checked={!property.businessHoursList[index].isOpen}
                                      />
                                      <label
                                        className="form-check-label mb-0 font-size-15"
                                        htmlFor={`closeDay${index}`}
                                      >
                                        Closed
                                      </label>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end align-items-center">
              <button
                type="button"
                className="btn btn-outline-dark px-5 me-3"
                onClick={() => {
                  closePropertyModal();
                }}
              >
                Cancel
              </button>
              {!isEditProperty && (
                <button className="btn btn-primary px-5" type="submit">
                  Save
                </button>
              )}
              {isEditProperty && (
                <button className="btn btn-primary px-5" type="submit">
                  Update
                </button>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ConfirmationModal
        show={syncUnitsModal}
        hide={closeSyncConfirmation}
        mainTitle="Confirm Sync"
        loadingState={setLoadingState}
        toast={showToast}
        propertyDetail={property}
        hideModal={onlyHide}
        openModal={onlyShow}
        icon="icon-sync"
        subTitle="This action will delete current units in JobCall system and load all the units from Resman."
      />
    </>
  );
}
