import { useState, useEffect } from 'react';
import { DEVICES } from '../constants/devices';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const getDeviceName = width => {
  if (width < 768) {
    return DEVICES.MOBILE;
  } else if (width < 992) {
    return DEVICES.TABLET;
  } else {
    return DEVICES.DESKTOP;
  }
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [deviceName, setDeviceName] = useState(DEVICES.UNKNOWN);

  useEffect(() => {
    setDeviceName(getDeviceName(windowDimensions.width));
  }, [windowDimensions]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return [windowDimensions, deviceName];
};

export default useWindowDimensions;
