import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import MultiSelect from 'react-multiple-select-dropdown-lite';
import 'react-multiple-select-dropdown-lite/dist/index.css';
import { REQUEST_STATUS } from '../../constants/loading';
import { USER_ROLES } from '../../constants/roles';
import { getRoleLabel } from '../../helpers/helpers';
import { inviteUser } from '../../services/dashBoardServices';
import AuthContext from '../../store/AuthContext';

const userInitial = {
  firstName : '',
  lastName : '',
  email: '',
  userRole: '',
};

const errorInitital = {
  firstName : false,
  lastName : false,
  email: false,
  userRole: false,
  userPropertyList: false,
};

const requiredFields = [
  { name: 'firstName', type: 'text' },
  { name: 'lastName', type: 'text' },
  { name: 'email', type: 'email' },
  { name: 'userRole', type: 'role' },
  { name: 'userPropertyList', type: 'multiselect' },
];

export default function InviteUserModal({
  show,
  onHide,
  optionPropertyList,
  showToast,
  setLoadingState,
}) {
  const authContext = useContext(AuthContext);

  const [errors, setErrors] = useState(errorInitital);
  const [user, setUser] = useState(userInitial);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [canSubmit, setCanSubmit] = useState(false);

  const updateUserVal = (value, field, type) => {
    let updateValue = value;
    if (type === 'text') {
      updateValue = value.replace(/^\s+/g, '');
      setErrors(prevErrors => ({
        ...prevErrors,
        [field]: updateValue.length < 1,
      }));
    } else if (type === 'email') {
      const validEmail =
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@([a-zA-Z0-9-]+[.]){1,2}[a-zA-Z]{2,10}$/.test(
          updateValue
        );
      setErrors(prevErrors => ({
        ...prevErrors,
        [field]: !validEmail,
      }));
    } else if (type === 'multiselect') {
      let error = false;
      if (user.userRole !== USER_ROLES.SYSTEM_ADMIN) {
        if (value === undefined) {
          error = true;
        } else if (typeof value === 'string') {
          updateValue = value.split(',');
          error = updateValue.length === 1 && value === '';
        } else {
          error = updateValue.length < 1;
        }
      }
      setErrors(prevErrors => ({
        ...prevErrors,
        [field]: error,
      }));
    } else if (type === 'role') {
      setErrors(prevErrors => ({
        ...prevErrors,
        [field]: updateValue.length < 1,
      }));
      if (value === USER_ROLES.SYSTEM_ADMIN) {
        setSelectedProperties([]);
      }
    } else {
      setErrors(prevErrors => ({
        ...prevErrors,
        [field]: updateValue.length < 1,
      }));
    }
    setUser(prevUser => ({
      ...prevUser,
      [field]: updateValue,
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();

    requiredFields.map(field => {
      updateUserVal(user[field.name], field.name, field.type);
    });

    setCanSubmit(true);
  };

  const closeUserModal = useCallback(
    (reload = false) => {
      setUser(userInitial);
      setErrors(errorInitital);
      setSelectedProperties([]);
      onHide(reload);
    },
    [onHide]
  );

  const submitForm = useCallback(async () => {
    let hasError = false;
    Object.keys(errors).map(key => {
      if (errors[key]) {
        hasError = true;
      }
    });

    if (hasError) {
      showToast({
        type: 'danger',
        show: true,
        message: 'Form Data Invalid!',
      });
    } else {
      setLoadingState(REQUEST_STATUS.LOADING);
      const res =  await inviteUser(user);
      const resErr = res?.status && res?.status !== 200;

      if (resErr) {
        const message = res.data?.message
          ? res.data?.message
          : 'Something went wrong. Try again later';

        showToast({
          type: 'danger',
          show: true,
          message,
        });
      } else {
        showToast({
            show: true,
            message: 'User invited successfully',
            type: 'success',
        });
        closeUserModal(true);
      }
      setLoadingState(REQUEST_STATUS.SUCCESS);
    }
  }, [closeUserModal, errors, setLoadingState, showToast, user]);

  useEffect(() => {
    if (canSubmit) {
      setCanSubmit(false);
      submitForm();
    }
  }, [canSubmit, errors, submitForm]);

  return (
    <>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header>
          <h3 className="font-weight-bold mb-0">Invite User</h3>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="firstName" className="form-label">
                First Name <span className="text-danger">*</span>
              </label>
              <input
                  type="text"
                  className={errors?.firstName ? 'form-control is-invalid' : 'form-control'}
                  id="firstName"
                  value={user.firstName}
                  name="firstName"
                  onChange={e => updateUserVal(e.target.value, 'firstName', 'text')}
              />
              {errors?.firstName && <span className="text-danger">First name Required</span>}
            </div>
            <div className="mb-4">
              <label htmlFor="lastName" className="form-label">
                Last Name <span className="text-danger">*</span>
              </label>
              <input
                  type="text"
                  className={errors?.lastName ? 'form-control is-invalid' : 'form-control'}
                  id="lastName"
                  value={user.lastName}
                  name="lastName"
                  onChange={e => updateUserVal(e.target.value, 'lastName', 'text')}
              />
              {errors?.lastName && <span className="text-danger">Last name Required</span>}
            </div>
            <div className="mb-4">
              <label htmlFor="emailAddress" className="form-label">
                Email address <span className="text-danger">*</span>
              </label>
              <input
                type="email"
                className={errors?.email ? 'form-control is-invalid' : 'form-control'}
                name="email"
                id="emailAddress"
                value={user.email}
                onChange={e => updateUserVal(e.target.value, 'email', 'email')}
              />
              {errors?.email && <span className="text-danger">Invalid email address</span>}
            </div>
            <div className="mb-4">
              <label htmlFor="role" className="form-label">
                Role
              </label>
              <Dropdown
                className="custom-dropdown"
                onSelect={val => updateUserVal(val, 'userRole', 'role')}
              >
                <Dropdown.Toggle className="outline-toggle" variant="link" id="role">
                  {getRoleLabel(user.userRole)}
                </Dropdown.Toggle>
                <Dropdown.Menu className="full-width">
                  {authContext.isSysAdmin && (
                    <Dropdown.Item eventKey={USER_ROLES.SYSTEM_ADMIN}>System Admin</Dropdown.Item>
                  )}
                  <Dropdown.Item eventKey={USER_ROLES.PROPERTY_ADMIN}>Property Admin</Dropdown.Item>
                  <Dropdown.Item eventKey={USER_ROLES.MAINTENANCE_ADMIN}>
                    Maintenance Admin
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {errors?.userRole && <span className="text-danger">User role required</span>}
            </div>
            {user.userRole !== USER_ROLES.SYSTEM_ADMIN && (
              <div className="mb-5">
                <label htmlFor="propertyList" className="form-label">
                  Property
                </label>
                <MultiSelect
                  className="custom-multiple-select"
                  onChange={val => {
                    updateUserVal(val, 'userPropertyList', 'multiselect');
                  }}
                  placeholder="Choose properties"
                  options={optionPropertyList}
                  defaultValue={selectedProperties}
                />
                {errors?.userPropertyList && <span className="text-danger">Property required</span>}
              </div>
            )}
            <div className="d-flex justify-content-end align-items-center">
              <button
                className="btn btn-outline-dark px-5 me-3"
                type="button"
                onClick={() => {
                  closeUserModal();
                }}
              >
                Cancel
              </button>
              <button
                  className="btn btn-primary px-5"
                  type="submit"
                  autoFocus={true}
                  onClick={handleSubmit}
              >
                  Invite
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
