import {Spinner} from "react-bootstrap";

const Loader = (props) => {
    return (
        <>
            {
                props.isLoading &&
                <>
                    <div className='loader-wrap blur-backdrop vw-100 vh-100 fixed-top d-flex align-items-center justify-content-center'>
                        <Spinner animation="border" />
                    </div>
                </>
            }
        </>
    )
}

export default Loader;