import React from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ToastMessage = ({ show = false, message = '', type = 'danger', onClose, delay = 5000 }) => {
  return (
    <ToastContainer position="bottom-end" className="nortify">
      <Toast bg={type} onClose={onClose} show={show} delay={delay} onClick={onClose} autohide>
        <Toast.Body className="text-white text-capitalize">{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

ToastMessage.propTypes = {
  delay: PropTypes.number,
  message: PropTypes.string,
  onClose: PropTypes.any,
  show: PropTypes.bool.isRequired,
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'dark',
    'light',
  ]).isRequired,
};

export default ToastMessage;
