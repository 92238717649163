import moment from 'moment';

export const getMonthDateTime = date => {
  return moment(date).format('MM/DD [at] h:mma');
};

export const getMonthDateYearTime = date => {
  return moment(date).format('DD MMM yyyy h:mm a');
};

export const getDateAtTime = date => {
  return moment(date).format('DD MMM yyyy h:mm a');
};

/**
 *
 * @param {Date} date
 * @returns
 */
export const getTzStartDate = date => {
  return new Date(moment(date).startOf('week').add(1, 'days'));
};

export const getSettingsDateValue = date => {
  return moment(date).format('dddd MMM. DD');
};

export const getWeekNames = startDate => {
  let weekStartDate = moment(startDate);
  let weekEndDate = moment(weekStartDate).add(6, 'days');
  let weekNames = [
    { label: '', value: 1 },
    { label: '', value: 2 },
    { label: '', value: 3 },
    { label: '', value: 4 },
  ];
  let step = 1;
  while (step < 5) {
    if (weekStartDate.get('month') === weekEndDate.get('month')) {
      weekNames[step - 1].label = `${weekStartDate.format('DD')} - ${weekEndDate.format('DD MMM')}`;
    } else {
      weekNames[step - 1].label = `${weekStartDate.format('DD MMM')} - ${weekEndDate.format(
        'DD MMM'
      )}`;
    }
    weekStartDate.add(7, 'days');
    weekEndDate.add(7, 'days');
    step++;
  }

  return weekNames;
};

export const getWeekDates = (date, week, order) => {
  return moment(date)
    .add(week - 1, 'weeks')
    .add(order - 1, 'days')
    .toDate();
};
