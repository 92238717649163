function getLocalData(key) {
  return JSON.parse(localStorage.getItem(key));
}

function addLocalData(key, dataObject) {
  localStorage.setItem(key, JSON.stringify(dataObject));
}

function removeLocalData(key) {
  localStorage.removeItem(key);
}

export function getAuthData() {
  return getLocalData('auth');
}

export function updateAuthData(authObject) {
  addLocalData('auth', authObject);
}

export function getLoginedUser() {
  return getLocalData('user');
}

export function removeUserData() {
  removeLocalData('user');
}

export function getSelectedProperty() {
  return getLocalData('selectedPropery');
}

export function updateSelectedProperty(propertyObject) {
  addLocalData('selectedPropery', propertyObject);
}
