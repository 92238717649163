import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

const PropertySwitcher = props => {
  return (
    <>
      <div className="header mb-3">
        <div className="container">
          <div className="header-body">
            <div className="row align-items-end">
              <div className="col">
                <h6 className="header-pretitle text-uppercase">{props.subTitle}</h6>
                {props.propLists.length === 1 ? (
                  <h1 className="font-size-28 mb-0">
                    {props.selectedProp?.propertyName || 'Property Not Set'}
                  </h1>
                ) : (
                  <Dropdown className="custom-dropdown no-arrow">
                    <Dropdown.Toggle
                      variant="link"
                      id="dropdown-basic"
                      className="property-switcher d-flex align-items-center"
                    >
                      <h3 className="font-size-28 mb-0 text-truncate">
                        {props.selectedProp?.propertyName || 'Property Not Set'}
                      </h3>
                      <i className="icon-chevron-down ms-3 font-size-20"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {props.propLists.map((item, index) => {
                        return (
                          <Dropdown.Item
                            onClick={() => props.onChange(item)}
                            value={item}
                            key={item.propertyId}
                            as="button"
                          >
                            {item.propertyName}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertySwitcher;
