import React, { useCallback, useContext, useEffect, useState } from 'react';

import AuthContext from '../../store/AuthContext';
import {
  deleteProperty,
  fetchPropList,
  fetchResmanPropertyList,
} from '../../services/dashBoardServices';
import { REQUEST_STATUS } from '../../constants/loading';
import Loader from '../../components/Loader/Loader';
import {
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Form,
  Image,
  Pagination,
  Toast,
  ToastContainer,
} from 'react-bootstrap';
import { getUSFormat } from '../../helpers/helpers';
import moreIcon from '../../assets/images/more.svg';
import EditPropertyModal from '../../components/Property/EditPropertyModal';
import useFetchApiList from '../../hooks/useFetchApi';
import { DEVICES } from '../../constants/devices';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import EditIcon from '../../assets/images/icon-edit.png';
import DeleteIcon from '../../assets/images/icon-delete.png';
import SearchIcon from '../../assets/images/icon-fc-search.png';
import { debounce } from 'lodash';

export default function PropertyList() {
  const authContext = useContext(AuthContext);
  const pageSize = 20;
  const initialPropertyListSpec = {
    currentPage: 1,
    offset: 0,
    pageSize: pageSize,
    pageMax: 1,
    sortColumn: 'propertyName',
    sortDirection: 'ASC',
    totalRecords: 0,
  };

  const [selectedProperty, setSelectedProperty] = useState(null);
  const [resmanPropListOption, setResmanPropListOption] = useState([]);
  const [resmanPropListState, setResmanPropListState] = useState(REQUEST_STATUS.IDLE);
  const [, deviceName] = useWindowDimensions();

  const [showPropertyModal, setShowPropertyModal] = useState(false);
  const [showDeletePropModal, setShowDeletePropModal] = useState(false);
  const [deletePropSpec, setDeletePropSpec] = useState();
  const [isEditForm, setIsEditForm] = useState(false);
  const [propertyFormState, setPropertyFormState] = useState(REQUEST_STATUS.IDLE);
  const [error, setError] = useState({
    message: '',
    show: false,
    bg: '',
  });

  const transformListSpec = listSpec => {
    return {
      offset: (listSpec.currentPage - 1) * pageSize,
      pageSize,
      sortColumn: listSpec.sortColumn,
      sortDirection: listSpec.sortDirection,
      loggedInUserId: authContext.user.id,
      searchText: listSpec.searchText,
    };
  };

  const {
    list: propertyList,
    listSpec: propertyListSpec,
    listState: propertyListState,
    getListSortReq: getPropListSortReq,
    getListPageReq: getPropListPageReq,
    getListQueryReq: getPropListQueryReq,
    setListState: setPropertyListState,
  } = useFetchApiList({
    fetchApi: fetchPropList,
    transformListSpec,
    listSpecInitial: initialPropertyListSpec,
  });

  const isMobile = deviceName === DEVICES.MOBILE || deviceName === DEVICES.TABLET;

  useEffect(() => {
    if (resmanPropListState === REQUEST_STATUS.IDLE) {
      setResmanPropListState(REQUEST_STATUS.PENDING);
    }
    if (resmanPropListState === REQUEST_STATUS.PENDING) {
      setResmanPropListState(REQUEST_STATUS.LOADING);
      const resmanPropertyDataList = async () => {
        await getResmanPropertyList();
        setResmanPropListState(REQUEST_STATUS.SUCCESS);
      };
      resmanPropertyDataList();
    }
  }, [resmanPropListState]);

  const getResmanPropertyList = async () => {
    const resmanList = await fetchResmanPropertyList();
    resmanList.sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    });

    const listOptions = resmanList.map(item => ({
      label: item.name,
      value: item.property_id,
    }));

    setResmanPropListOption(listOptions);
  };

  const closeToastr = () => {
    setError(prevError => {
      return {
        ...prevError,
        show: false,
      };
    });
  };

  const getSortByClass = useCallback(
    sortColumn => {
      return propertyListSpec.sortColumn !== sortColumn
        ? 'list-sort'
        : propertyListSpec.sortDirection === 'ASC'
        ? 'list-sort list-sort-asc'
        : 'list-sort list-sort-desc';
    },
    [propertyListSpec]
  );

  const handleShowPropertyModal = (isEditForm, property) => {
    setSelectedProperty(property);
    setIsEditForm(isEditForm);
    setShowPropertyModal(true);
  };

  const handleHideModal = useCallback(
    (reload = false) => {
      setSelectedProperty(null);
      setIsEditForm(false);
      setShowPropertyModal(false);
      if (reload) {
        setPropertyListState(REQUEST_STATUS.PENDING);
      }
    },
    [setPropertyListState]
  );

  const handleQuery = debounce(query => {
    getPropListQueryReq(query);
  }, 500);

  const onlyHidePropModal = () => {
    setShowPropertyModal(false);
  };

  const onlyShowPropModal = () => {
    setShowPropertyModal(true);
  };

  const openDeletePropModal = propDelete => {
    setDeletePropSpec(propDelete);
    setShowDeletePropModal(true);
  };

  const closeDeletePropModal = () => setShowDeletePropModal(false);

  const handleDeleteProperty = async id => {
    closeDeletePropModal();
    setPropertyFormState(REQUEST_STATUS.LOADING);
    const deleteRes = await deleteProperty(id);
    if (deleteRes?.status === 400) {
      setError({ show: true, message: deleteRes.data.message, bg: 'danger' });
    }
    setPropertyFormState(REQUEST_STATUS.SUCCESS);
    setPropertyListState(REQUEST_STATUS.PENDING);
  };

  return (
    <>
      <Loader
        isLoading={
          propertyListState === REQUEST_STATUS.LOADING ||
          propertyFormState === REQUEST_STATUS.LOADING
        }
      />
      <div className="header no-mobile-border mb-3">
        <div className="container">
          <div className="header-body">
            <div className="list-header">
              {authContext.isSysAdmin && (
                <button
                  className="btn btn-primary header-btn"
                  onClick={() => {
                    handleShowPropertyModal(false, null);
                  }}
                >
                  Add Property
                </button>
              )}
              <div className="icon-input">
                <span className="icon">
                  <Image src={SearchIcon} />
                </span>
                <Form.Control
                  readOnly={
                    propertyListState === REQUEST_STATUS.PENDING ||
                    propertyListState === REQUEST_STATUS.LOADING
                  }
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="search-users"
                  onChange={event => handleQuery(event.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        {isMobile ? (
          <div>
            {propertyList?.map((listItem, index) => {
              return (
                <div key={index} className="card mb-3">
                  <div className="card-body d-flex justify-content-between">
                    <div>
                      <h3 className="font-size-20 mb-3">{listItem.propertyName}</h3>
                      <p className="font-size-xs text-muted text-uppercase mb-2">
                        AMAZON CONNECT EMERGENCY PHONE NUMBER
                      </p>
                      <p className="mb-0">
                        {getUSFormat(listItem.amazonConnectNumber)}{' '}
                      </p>
                      <p className="font-size-xs text-muted text-uppercase mb-2">
                        AMAZON CONNECT MAINTENANCE PHONE NUMBER
                      </p>
                      <p className="mb-0">
                        {getUSFormat(listItem.amazonConnectMaintenanceNumber)}{' '}
                      </p>
                      <p className="font-size-xs text-muted text-uppercase mb-2">
                        TIMEZONE
                      </p>
                      <p className="mb-0">
                        {listItem.timeZone === 'us_central' && 'US Central'}
                        {listItem.timeZone === 'us_pacific' && 'US Pacific'}
                        {listItem.timeZone === 'us_mountain' && 'US Mountain'}
                        {listItem.timeZone === 'us_eastern' && 'US Eastern'}
                      </p>
                    </div>
                    <div>
                      <DropdownButton
                        as={ButtonGroup}
                        variant="link"
                        key={index}
                        className="line-height-1 no-arrow custom-dropdown ms-1"
                        title={<i className="icon-more-vertical text-black font-size-20"></i>}
                      >
                        <Dropdown.Item
                          as="button"
                          onClick={() => {
                            handleShowPropertyModal(true, listItem);
                          }}
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          className="text-danger"
                          onClick={() => {
                            openDeletePropModal(listItem);
                          }}
                        >
                          Delete
                        </Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </div>
                </div>
              );
            })}

            {!propertyList?.length && (
              <div className="card">
                <div className="card-body">
                  <p className="mb-0 py-4 text-center">Nothing Here</p>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="card">
            <div className="table-responsive mb-0">
              <table className="table table-sm table-nowrap card-table head-light">
                <thead>
                  <tr>
                    <th>
                      <a
                        href="#/"
                        type="button"
                        className={`text-muted ${getSortByClass('propertyName')}`}
                        onClick={() => getPropListSortReq('propertyName')}
                      >
                        Property name
                      </a>
                    </th>
                    <th>
                        Amazon Connect Emergency Phone Number
                    </th>
                    <th>
                        Amazon Connect Maintenance Phone Number
                    </th>
                    <th>
                      <a
                        href="#/"
                        type="button"
                        className={`text-muted ${getSortByClass('timeZone')}`}
                        onClick={() => getPropListSortReq('timeZone')}
                      >
                        Timezone
                      </a>
                    </th>
                    {authContext.isSysAdmin && <th>Action</th>}
                  </tr>
                </thead>
                <tbody>
                  {propertyList?.map((listItem, index) => {
                    return (
                      <tr key={index}>
                        <td>{listItem.propertyName}</td>
                        <td>{getUSFormat(listItem.amazonConnectNumber)}</td>
                        <td>{getUSFormat(listItem.amazonConnectMaintenanceNumber)}</td>
                        <td>
                          {listItem.timeZone === 'us_central' && 'US Central'}
                          {listItem.timeZone === 'us_pacific' && 'US Pacific'}
                          {listItem.timeZone === 'us_mountain' && 'US Mountain'}
                          {listItem.timeZone === 'us_eastern' && 'US Eastern'}
                        </td>
                        {authContext.isSysAdmin && (
                          <td>
                            {listItem?.propertyName !== 'Default Property' && (
                              <>
                                <button
                                  className="btn btn-icon"
                                  onClick={() => {
                                    handleShowPropertyModal(true, listItem);
                                  }}
                                >
                                  <Image src={EditIcon} />
                                </button>
                                <button
                                  className="btn btn-icon ms-3"
                                  onClick={() => {
                                    openDeletePropModal(listItem);
                                  }}
                                >
                                  <Image src={DeleteIcon} />
                                </button>
                              </>
                            )}
                          </td>
                        )}
                      </tr>
                    );
                  })}
                  {!propertyList?.length && (
                    <tr>
                      <td colSpan="8">
                        <p className="mb-0 py-4 text-center">Nothing Here</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <EditPropertyModal
          show={showPropertyModal}
          propertyData={selectedProperty}
          isEditProperty={isEditForm}
          onHide={handleHideModal}
          onlyHide={onlyHidePropModal}
          onlyShow={onlyShowPropModal}
          resmanPropListOption={resmanPropListOption}
          showToast={setError}
          setLoadingState={setPropertyFormState}
        />
        <DeleteModal
          showModal={showDeletePropModal}
          closeModal={closeDeletePropModal}
          titleName={'Property'}
          entityId={deletePropSpec?.propertyId}
          entityName={deletePropSpec?.propertyName}
          onDelete={handleDeleteProperty}
        />
        {propertyListSpec.pageMax > 1 && (
          <div className="pagination-wrap">
            <div className="page-number">{`Page ${propertyListSpec.currentPage} of ${propertyListSpec.pageMax}`}</div>
            <Pagination>
              <Pagination.First
                onClick={() => {
                  getPropListPageReq(1);
                }}
                disabled={propertyListSpec.currentPage === 1}
              />
              <Pagination.Prev
                onClick={() => getPropListPageReq(propertyListSpec.currentPage - 1)}
                disabled={propertyListSpec.currentPage === 1}
              />
              <Pagination.Next
                onClick={() => getPropListPageReq(propertyListSpec.currentPage + 1)}
                disabled={propertyListSpec.pageMax === propertyListSpec.currentPage}
              />
              <Pagination.Last
                onClick={() => {
                  getPropListPageReq(propertyListSpec.pageMax);
                }}
                disabled={propertyListSpec.pageMax === propertyListSpec.currentPage}
              />
            </Pagination>
          </div>
        )}
        <ToastContainer
          containerPosition="fixed"
          position="bottom-end"
          className="custom-toastr p-3"
        >
          <Toast bg={error.bg} onClose={closeToastr} show={error.show} delay={5000} autohide>
            <Toast.Body className="text-white text-capitalize">{error.message}</Toast.Body>
          </Toast>
        </ToastContainer>
      </div>
    </>
  );
}
