import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { optionalKeys } from '../../pages/CallSettings/CallSettings';

const getPropertyUsersName = (propertyUsers, userId) => {
  if (propertyUsers && propertyUsers.length) {
    const user = propertyUsers.find(user => user.id === userId);
    if (user) {
      return user.firstName + ' ' + user.lastName;
    }
  }

  return 'Choose Contact';
};

const UserSelect = ({ propertyUsers, userId, rowId, contactType, showError, handleSelectUser }) => {
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    const selected = getPropertyUsersName(propertyUsers, userId);
    setSelectedUser(selected);
  }, [propertyUsers, userId]);

  if (!propertyUsers || !propertyUsers.length) {
    return null;
  }

  return (
    <Dropdown className={`custom-table-dropdown ${showError ? 'has-error' : ''}`}>
      <Dropdown.Toggle>{selectedUser}</Dropdown.Toggle>

      <Dropdown.Menu>
        {optionalKeys.includes(contactType) ? (
          <Dropdown.Item
            onClick={() => handleSelectUser(rowId, contactType, null)}
            key={'default-select'}
          >
            Choose Contact
          </Dropdown.Item>
        ) : null}
        {propertyUsers.map(user => {
          return (
            <Dropdown.Item
              onClick={() => handleSelectUser(rowId, contactType, user.id)}
              key={user.id}
            >
              {`${user.firstName} ${user.lastName}`}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UserSelect;
