import React from 'react';
import useAudio from '../../hooks/useAudio';
import styled from 'styled-components';
import { rem } from '../../helpers/units';
import { colorList } from '../../helpers/colorList';
import { icons } from '../../constants/icons';

const PlayButton = styled.button`
  display: flex;
  align-items: center;
  border: 0;
  padding: 0;
  background: none;
`;

const Img = styled.img`
  width: ${rem(32)};
  margin-right: ${rem(8)};
`;

const Text = styled.p`
  font-size: ${rem(13)};
  line-height: ${rem(16)};
  letter-spacing: ${rem(-0.01)};
  text-align: left;
  margin-bottom: 0;
  margin-bottom: ${rem(-2)};
  color: ${colorList.blue};
`;

const PlayAudioWaveButton = ({ audioLink }) => {
  const [isPlaying, togglePlay, duration] = useAudio(audioLink);
  return (
    <>
      <PlayButton
        className="audio-icon-only"
        onClick={() => {
          togglePlay();
        }}
      >
        {isPlaying ? (
          <i className="icon-pause-circle text-primary font-size-28"></i>
        ) : (
          <i className="icon-speaker text-primary font-size-28"></i>
        )}
      </PlayButton>
      <div className="audio-waves">
        <div className="audio-details d-flex justify-content-between align-items-center mb-3">
          <button
            className="btn p-0 line-height-1"
            onClick={() => {
              togglePlay();
            }}
          >
            {isPlaying ? (
              <img src={icons.pauseIconAlt} alt="pause" />
            ) : (
              <img src={icons.playAltIcon} alt="play" />
            )}
          </button>
          <img className="audio-waves" src={icons.audioWaveIcon} alt="..." />
          <p className="text-white mb-0">{duration !== 0 && Math.round(duration) + 's'}</p>
        </div>
        <p className="font-size-xs text-center">
          Listen to a recording of the resident report the issue
        </p>
      </div>
    </>
  );
};

export default PlayAudioWaveButton;
