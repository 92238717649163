import React, { useEffect, useState } from 'react';
import { FormCheck, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PlayAudioButton from '../PlayAudio/PlayAudioButton';
import IssueBadge from '../IssueBadge/IssueBadge';
import { colorList } from '../../helpers/colorList';
import { getDateAtTime } from '../../helpers/date';
import { getCallEntryStatusLabel, getUSFormat } from '../../helpers/helpers';
import EmptyList from './EmptyList';

export default function CallListDesktop({
  callList,
  fetchCallsSort,
  getSortByClass,
  openEditTaskModal,
  callAction,
  setLoading,
  isOpen,
  selectedJobs,
  setSelectedJobs,
}) {
  const typeKey = isOpen ? 'open' : 'close';

  const handleSelectedJobs = event => {
    const value = event.target.value;
    const jobsSelected = { ...selectedJobs };
    if (jobsSelected[typeKey].indexOf(value) >= 0) {
      jobsSelected[typeKey]?.splice(jobsSelected[typeKey].indexOf(value), 1);
    } else {
      jobsSelected[typeKey]?.push(value);
    }
    setSelectedJobs(jobsSelected);
  };

  const handleMultiSelect = () => {
    let ids = [];
    if (selectedJobs[typeKey]?.length !== callList.list.length) {
      ids = callList.list.map(item => item.id);
    }
    setSelectedJobs(prevJobs => {
      const jobs = { ...prevJobs };
      jobs[typeKey] = ids;
      return jobs;
    });
  };

  useEffect(() => {
    setSelectedJobs({ open: [], close: [] });
  }, [callList, setSelectedJobs]);

  return (
    <>
      {callList?.list?.length ? (
        <div className="card">
          <div className="table-responsive mb-0">
            <table className="table table-sm table-nowrap card-table head-light">
              <thead>
                <tr>
                  <th>
                    <FormCheck
                      type="checkbox"
                      checked={
                        selectedJobs[typeKey]?.length === callList.list?.length &&
                        callList.list?.length > 0
                      }
                      onChange={handleMultiSelect}
                    />
                  </th>
                  <th>
                    <a
                      href="#/"
                      type="button"
                      className={`text-muted ${getSortByClass(isOpen, 'unit')}`}
                      onClick={() => fetchCallsSort(isOpen, 'unit')}
                    >
                      Unit
                    </a>
                  </th>
                  <th>
                    <a
                      href="#/"
                      type="button"
                      className={`text-muted ${getSortByClass(isOpen, 'residentFirstName')}`}
                      onClick={() => fetchCallsSort(isOpen, 'residentFirstName')}
                    >
                      Resident Name
                    </a>
                  </th>
                  <th>
                    <a
                      href="#/"
                      type="button"
                      className={`text-muted ${getSortByClass(isOpen, 'residentPhoneNumber')}`}
                      onClick={() => fetchCallsSort(isOpen, 'residentPhoneNumber')}
                    >
                      Resident Phone
                    </a>
                  </th>
                  <th>
                    <a
                      href="#/"
                      type="button"
                      className={`text-muted ${getSortByClass(isOpen, 'maintenanceIssue')}`}
                      onClick={() => fetchCallsSort(isOpen, 'maintenanceIssue')}
                    >
                      Maintenance ISSUE
                    </a>
                  </th>
                  <th>Description</th>
                  <th>
                    <a
                      href="#/"
                      type="button"
                      className={`text-muted ${getSortByClass(isOpen, 'maintenancePhoneNumber')}`}
                      onClick={() => fetchCallsSort(isOpen, 'maintenancePhoneNumber')}
                    >
                      Answered By
                    </a>
                  </th>
                  <th>
                    <a
                      href="#/"
                      type="button"
                      className={`text-muted ${getSortByClass(isOpen, 'callTime')}`}
                      onClick={() => fetchCallsSort(isOpen, 'callTime')}
                    >
                      Call Date/Time
                    </a>
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {callList?.list?.map(callItem => {
                  return (
                    <tr key={callItem?.id}>
                      <td>
                        <FormCheck
                          type="checkbox"
                          checked={
                            selectedJobs[typeKey]?.find(item => item === callItem?.id) ?? false
                          }
                          onChange={handleSelectedJobs}
                          value={callItem?.id}
                        />
                      </td>
                      <td className="text-truncate">
                        <div className="d-flex align-items-center position-relative">
                          {callItem?.unitAudioUrl && (
                            <PlayAudioButton audioLink={callItem?.unitAudioUrl} />
                          )}
                          <p
                            className={
                              callItem?.unitAudioUrl
                                ? 'mb-0 mt-1 ps-4 text-truncate'
                                : 'mb-0 mt-1 text-truncate'
                            }
                          >
                            {callItem?.unit}
                          </p>
                          {!callItem?.isValidUnit && (
                            <OverlayTrigger
                              placement="top"
                              delay={{
                                show: 250,
                                hide: 400,
                              }}
                              overlay={
                                <Tooltip>
                                  This unit number is invalid. Please edit it to create a work order
                                  in ResMan.
                                </Tooltip>
                              }
                            >
                              <button
                                type="button"
                                className="btn px-1 pb-0 line-height-1 font-size-sm unit-error-icon"
                              >
                                <i className="icon-caution text-danger"></i>
                              </button>
                            </OverlayTrigger>
                          )}
                        </div>
                      </td>

                      <td className="text-capitalize text-truncate">
                        {callItem?.residentFirstName}
                      </td>
                      <td>{getUSFormat(callItem.residentPhoneNumber)}</td>

                      <td className="text-capitalize text-truncate">
                        <IssueBadge type={callItem?.maintenanceIssue} />
                      </td>

                      <td className="text-truncate">
                        <div className="d-flex align-items-center position-relative">
                          {callItem?.descriptionAudioUrl && (
                            <PlayAudioButton audioLink={callItem?.descriptionAudioUrl} />
                          )}
                          <OverlayTrigger
                            placement="bottom"
                            delay={{
                              show: 250,
                              hide: 400,
                            }}
                            overlay={
                              <Tooltip className="custom-tooltip">{callItem?.description}</Tooltip>
                            }
                          >
                            <button
                              className={
                                callItem?.descriptionAudioUrl
                                  ? 'btn p-0 ps-4 w-100 font-size-sm text-truncate text-start'
                                  : 'btn p-0 w-100 font-size-sm text-truncate text-start'
                              }
                            >
                              {callItem?.description}
                            </button>
                          </OverlayTrigger>
                        </div>
                      </td>
                      <td
                        className="text-truncate"
                        style={{
                          color:
                            callItem?.callEntryStatus === 'answered'
                              ? colorList.black
                              : colorList.gray800,
                        }}
                      >
                        {callItem?.callEntryStatus === 'answered'
                          ? callItem.maintenanceUserName
                          : getCallEntryStatusLabel(callItem?.callEntryStatus)}
                      </td>
                      <td>{getDateAtTime(callItem?.callTime)}</td>

                      <td>
                        <button
                          type="button"
                          className="btn btn-sm btn-primary me-1"
                          onClick={() =>
                            callAction(callItem.id, {
                              updateAction: isOpen ? 'close' : 'open',
                            }).then(() => {
                              setLoading(false);
                            })
                          }
                        >
                          {isOpen ? 'Mark as closed' : 'Re-open'}
                        </button>
                        <OverlayTrigger
                          placement="top"
                          delay={{
                            show: 250,
                            hide: 400,
                          }}
                          overlay={<Tooltip>View</Tooltip>}
                        >
                          <Link
                            to={`call-log/${callItem?.id}`}
                            className="btn btn-sm has-icon-only btn-secondary me-1"
                          >
                            <i className="icon-eye"></i>
                          </Link>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="top"
                          delay={{
                            show: 250,
                            hide: 400,
                          }}
                          overlay={<Tooltip>Edit</Tooltip>}
                        >
                          <button
                            onClick={e => openEditTaskModal(e, callItem)}
                            type="button"
                            className="btn btn-sm has-icon-only btn-warning"
                          >
                            <i className="icon-edit"></i>
                          </button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <EmptyList
          caption={`There are no ${
            isOpen ? 'open' : 'closed'
          } calls at this property. Try adjusting the filters to view call entries.`}
        />
      )}
    </>
  );
}
