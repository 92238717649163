import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import Loader from "../../components/Loader/Loader";
import {REQUEST_STATUS} from "../../constants/loading";
import {Dropdown} from "react-bootstrap";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import logo from "../../assets/images/logo.svg";
import {registerUser, validateUserInvitationToken} from "../../services/dashBoardServices";

const errorInitial = {
    firstName: false,
    lastName: false,
    contactNumber: false,
    preferredLanguage: false,
};

const requiredFields = [
    {name: 'firstName', type: 'text'},
    {name: 'lastName', type: 'text'},
    {name: 'contactNumber', type: 'phone'},
    {name: 'preferredLanguage', type: ''},
];

const toastInitial = {
    type: 'danger',
    show: false,
    message: '',
    delay: 5000,
};

export default function UserRegistration() {
    const {id} = useParams();
    const userInitial = {
        token: id,
        firstName: '',
        lastName: '',
        contactNumber: '',
        preferredLanguage: '',
    };
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [userRegistrationLoadState, setUserRegistrationLoadState] = useState(REQUEST_STATUS.IDLE);
    const [canSubmit, setCanSubmit] = useState(false);
    const [errors, setErrors] = useState(errorInitial);
    const [toast, setToast] = useState(toastInitial);
    const [user, setUser] = useState(userInitial);
    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const validateToken = useCallback(async () => {
        const res = await validateUserInvitationToken(id);
        const resErr = res?.status && res?.status !== 200;

        if (resErr) {
            const message = res.data?.message
                ? res.data?.message
                : 'Invalid invitation token. Please contact your administrator.';

            setToast({
                delay: 300000,
                type: 'danger',
                show: true,
                message,
            });
            setButtonDisabled(true);
        }
        setUser(prevUser => ({
            ...prevUser,
            ['firstName']: res.data.firstName,
            ['lastName']: res.data.lastName,
        }));
        setUserRegistrationLoadState(REQUEST_STATUS.SUCCESS);
    }, [userRegistrationLoadState]);

    useEffect(() => {
        if (userRegistrationLoadState === REQUEST_STATUS.IDLE) {
            setUserRegistrationLoadState(REQUEST_STATUS.PENDING);
        }
        if (userRegistrationLoadState === REQUEST_STATUS.PENDING) {
            setUserRegistrationLoadState(REQUEST_STATUS.LOADING);
            validateToken();
        }
    }, [validateToken, userRegistrationLoadState]);

    const getPrefLang = prefLang => {
        switch (prefLang) {
            case 'es':
                return 'Spanish';
            case 'en':
                return 'English';
            default:
                return 'Choose language';
        }
    };

    const updateUserVal = (value, field, type) => {
        let updateValue = value;
        if (type === 'text') {
            updateValue = value.replace(/^\s+/g, '');
            setErrors(prevErrors => ({
                ...prevErrors,
                [field]: updateValue.length < 1,
            }));
        } else if (type === 'phone') {
            updateValue = value.replace(/[^0-9.]/g, '');
            setErrors(prevErrors => ({
                ...prevErrors,
                [field]: updateValue.length !== 10,
            }));
        } else {
            setErrors(prevErrors => ({
                ...prevErrors,
                [field]: updateValue.length < 1,
            }));
        }
        setUser(prevUser => ({
            ...prevUser,
            [field]: updateValue,
        }));
    };

    const handleSubmit = async e => {
        e.preventDefault();
        requiredFields.map(field => {
            updateUserVal(user[field.name], field.name, field.type);
        });
        setCanSubmit(true);
    };

    const closeToast = () => {
        setToast(prevState => ({
            ...prevState,
            show: false,
        }));
    };

    const submitForm = useCallback(async () => {
        let hasError = false;
        Object.keys(errors).map(key => {
            if (errors[key]) {
                hasError = true;
            }
        });

        if (hasError) {
            setToast({
                type: 'danger',
                show: true,
                message: 'Form Data Invalid!',
            });
        } else {
            setUserRegistrationLoadState(REQUEST_STATUS.LOADING);
            const res = await registerUser(id, user);
            const resErr = res?.status && res?.status !== 200;

            if (resErr) {
                const message = res.data?.message
                    ? res.data?.message
                    : 'Something went wrong. Please contact support';

                setToast({
                    delay: 300000,
                    type: 'danger',
                    show: true,
                    message,
                });
            } else {
                if (user.contactNumber.length === 10) {
                    const formattedMobileNumber = user.contactNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                    history.push(`/?mobileNumber=${encodeURIComponent(formattedMobileNumber)}`);
                } else {
                    history.push(`/`);
                }
            }
            setUserRegistrationLoadState(REQUEST_STATUS.SUCCESS);
        }
    }, [errors, setUserRegistrationLoadState, setToast, user]);

    useEffect(() => {
        if (canSubmit) {
            setCanSubmit(false);
            submitForm();
        }
    }, [canSubmit, errors, submitForm]);


    return (
        <>
            <Loader
                isLoading={
                    isLoading ||
                    userRegistrationLoadState === REQUEST_STATUS.LOADING
                }
            />
            <div className="d-flex align-items-center justify-content-center vh-100">
                <div className="login-wrapper">
                    <div className="container">
                        <div className="logo-wrap text-center">
                            <img src={logo} alt="logo"/>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <h1 className="heading1 login-heading text-center">Sign Up</h1>
                            <div className="mb-4">
                                <label htmlFor="firstName" className="form-label">
                                    First Name <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    className={errors?.firstName ? 'form-control is-invalid' : 'form-control'}
                                    id="firstName"
                                    value={user.firstName}
                                    name="firstName"
                                    onChange={e => updateUserVal(e.target.value, 'firstName', 'text')}
                                />
                                {errors?.firstName && <span className="text-danger">First name Required</span>}
                            </div>
                            <div className="mb-4">
                                <label htmlFor="lastName" className="form-label">
                                    Last Name <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    className={errors?.lastName ? 'form-control is-invalid' : 'form-control'}
                                    id="lastName"
                                    value={user.lastName}
                                    name="lastName"
                                    onChange={e => updateUserVal(e.target.value, 'lastName', 'text')}
                                />
                                {errors?.lastName && <span className="text-danger">Last name Required</span>}
                            </div>
                            <div className="mb-4">
                                <label htmlFor="phoneNumber" className="form-label">
                                    Phone number <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    pattern="[0-9]*"
                                    maxLength="10"
                                    className={errors?.contactNumber ? 'form-control is-invalid' : 'form-control'}
                                    name="contactNumber"
                                    id="phoneNumber"
                                    value={user.contactNumber}
                                    onChange={e => updateUserVal(e.target.value, 'contactNumber', 'phone')}
                                />
                                {errors?.contactNumber && <span className="text-danger">Phone number Invalid</span>}
                            </div>
                            <div className="mb-4">
                                <label htmlFor="prefLanguage" className="form-label">
                                    Preferred language <span className="text-danger">*</span>
                                </label>
                                <Dropdown
                                    className="custom-dropdown"
                                    onSelect={val => updateUserVal(val, 'preferredLanguage')}
                                >
                                    <Dropdown.Toggle className="outline-toggle" variant="link">
                                        {getPrefLang(user.preferredLanguage)}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="full-width">
                                        <Dropdown.Item eventKey="en">English</Dropdown.Item>
                                        <Dropdown.Item eventKey="es">Spanish</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                {errors?.preferredLanguage && (
                                    <span className="text-danger">Preferred language required</span>
                                )}
                            </div>
                            <p>
                                By singing up, you agree to the{' '}
                                <a
                                    href="https://www.jobcall.com/terms"
                                    className="text-black text-decoration-underline"
                                >
                                    Terms of Service
                                </a>{' '}
                                &
                                <a
                                    href="https://www.jobcall.com/privacy"
                                    className="text-black text-decoration-underline"
                                >
                                    {' '}
                                    Privacy Policy
                                </a>
                                .
                            </p>
                            <button
                                className="btn btn-lg btn-primary btn-block"
                                type="button"
                                autoFocus={true}
                                onClick={handleSubmit}
                                disabled={isButtonDisabled}
                            >
                                Sign Up
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <ToastMessage
                show={toast.show}
                message={toast.message}
                type={toast.type}
                onClose={closeToast}
                delay={toast.delay}
            />
        </>
    );
};