import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { REQUEST_STATUS } from '../../constants/loading';
import { DEVICES } from '../../constants/devices';
import {
  fetchCallLogDetails,
  maskPhoneNumber,
  updateAction,
  updateCallTask,
} from '../../services/dashBoardServices';
import { getSelectedProperty, updateSelectedProperty } from '../../services/localServices';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import AuthContext from '../../store/AuthContext';

import EditTaskModal from '../../components/CallLog/EditTaskModal';
import PlayAudioWaveButton from '../../components/PlayAudio/PlayAudioWaveButton';
import IssueBadge from '../../components/IssueBadge/IssueBadge';
import { getMonthDateTime, getMonthDateYearTime } from '../../helpers/date';
import {
  getCallEntryStatusLabel,
  getDefaultFormat,
  getUSFormat,
  preloadImages,
} from '../../helpers/helpers';
import { colorList } from '../../helpers/colorList';
import playIcon from '../../assets/images/play.svg';
import pauseIcon from '../../assets/images/pause-circle.svg';
import ToastMessage from '../../components/ToastMessage/ToastMessage';
import { useCallback } from 'react';

const CallLogDetail = () => {
  const { id } = useParams();
  const editTaskInitial = {
    unit: '',
    residentFirstName: '',
    residentPhoneNumber: '',
    maintenanceIssue: '',
    description: '',
    id: '',
    isOpen: false,
  };
  const [callRecord, setCallRecord] = useState(null);
  const [reqState, setReqState] = useState(REQUEST_STATUS.IDLE);
  const [updateState, setUpdateState] = useState(REQUEST_STATUS.IDLE);
  const [toasterError, setToasterError] = useState({ show: false, message: '' });
  const [isMaskingNumber, setIsMaskingNumber] = useState(false);
  const [, deviceName] = useWindowDimensions();
  const authContext = useContext(AuthContext);
  //Edit task modal
  const [editTaskModal, setEditTaskModal] = useState(false);
  const [editTaskDetails, setEditTaskDetails] = useState(editTaskInitial);

  const getApiData = useCallback(async () => {
    await getCallLogDetail(id);
  }, [id]);

  useEffect(() => {
    preloadImages([playIcon, pauseIcon]);
    if (reqState === REQUEST_STATUS.IDLE) {
      setReqState(REQUEST_STATUS.LOADING);
    }
  }, [reqState]);

  useEffect(() => {
    if (reqState === REQUEST_STATUS.LOADING) {
      getApiData();
    }
  }, [reqState, getApiData]);

  useEffect(() => {
    if (updateState === REQUEST_STATUS.LOADING) {
      setUpdateState(REQUEST_STATUS.SUCCESS);
    }
  }, [callRecord, updateState]);

  const onCallAction = async () => {
    setUpdateState(REQUEST_STATUS.LOADING);
    const res = await updateAction(id, {
      updateAction: callRecord.isOpen ? 'close' : 'open',
    });
    setCallRecord(res);
  };

  const getCallLogDetail = async id => {
    fetchCallLogDetails(id).then(res => {
      if (res.id) {
        setReqState(REQUEST_STATUS.SUCCESS);
        setCallRecord(res);
        const selectedProp = getSelectedProperty();
        if (selectedProp.propertyId !== res.propertyId) {
          updateSelectedProperty({
            propertyId: res.propertyId,
            propertyName: res.propertyName,
          });
        }
      } else {
        setReqState(REQUEST_STATUS.FAILED);
      }
    });
  };

  const generateSingleUseNumber = async () => {
    setIsMaskingNumber(true);
    const maskedPayload = await maskPhoneNumber(
      authContext.user.contactNumber,
      callRecord.residentPhoneNumber,
      id
    );
    if (maskedPayload.error !== true) {
      setIsMaskingNumber(false);
      window.location.href = 'tel:' + maskedPayload?.callMaskNumber;
    } else {
      console.error(maskedPayload?.message ?? 'Unknown error');
      setToasterError({
        show: true,
        message: 'Generating call masking number failed. Please contact support',
      });
      setIsMaskingNumber(false);
    }
  };

  if (reqState === REQUEST_STATUS.LOADING) {
    return (
      <div className="cl-detail">
        <div className="container">
          <div className="cl-detail__loader">
            <div className="spinner-border text-primary" role="status"></div>
          </div>
        </div>
      </div>
    );
  }

  const openEditTaskModal = (e, taskList) => {
    setEditTaskModal(true);
    const list = { ...editTaskDetails };
    list.unit = taskList?.unit;
    list.residentFirstName = taskList?.residentFirstName;
    list.maintenanceIssue = taskList?.maintenanceIssue;
    list.description = taskList?.description;
    list.isOpen = taskList?.isOpen;
    list.residentPhoneNumber = getDefaultFormat(taskList?.residentPhoneNumber);
    list.id = taskList?.id;
    setEditTaskDetails(list);
  };

  const closeEditTaskModal = e => {
    e.preventDefault();
    setEditTaskModal(false);
  };

  const updateEditTask = async taskDetails => {
    const updateTaskRes = await updateCallTask(taskDetails?.id, taskDetails);
    if (updateTaskRes?.status === 400) {
      setToasterError({
        show: true,
        message: updateTaskRes?.message,
      });
    }
    await getCallLogDetail(taskDetails?.id);
    setEditTaskModal(false);
  };

  return (
    callRecord && (
      <>
        <div className="cl-detail">
          <div className="container">
            {/*detail-header*/}
            <div className="row">
              <div className="col-12">
                <div className="detail-header d-flex justify-content-between align-items-center">
                  <Link to="/call-log" className="text-black has-left-icon">
                    <i className="icon-chevron-left left-icon"></i>
                    Call log
                  </Link>
                  <button
                    onClick={e => openEditTaskModal(e, callRecord)}
                    className="btn btn-primary"
                  >
                    Edit Task
                  </button>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="card call-details-card">
                  <div className="card-body">
                    <div className="call-details-badge-group">
                      {callRecord?.callType === 'emergency' && (
                          <span className="badge badge-red badge-soft text-uppercase badge-pill me-2">
                          Emergency
                        </span>
                      )}
                      {callRecord?.callType === 'maintenance' && (
                          <span className="badge badge-red badge-soft text-uppercase badge-pill me-2">
                          Maintenance
                        </span>
                      )}
                      <IssueBadge
                        type={callRecord.maintenanceIssue}
                        adClassNames="badge-soft badge-pill text-uppercase me-2"
                      />
                      {/* <span className="badge badge-pink badge-soft badge-pill text-uppercase me-2">
                        {callRecord.maintenanceIssue}
                      </span> */}
                      {callRecord?.isOpen === true && (
                        <span className="badge badge-red badge-soft text-uppercase badge-pill">
                          Open Issue
                        </span>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-12 cl-detail__title mb-4">
                        <div className="d-flex align-items-center mb-3">
                          <div className="d-flex align-items-center">
                            <h1 className="mb-0 me-2">{callRecord.unit}</h1>
                            {!callRecord?.isValidUnit && (
                              <OverlayTrigger
                                placement="top"
                                delay={{
                                  show: 250,
                                  hide: 400,
                                }}
                                overlay={
                                  <Tooltip>
                                    This unit number is invalid. Please edit it to create a work
                                    order in ResMan.
                                  </Tooltip>
                                }
                              >
                                <button
                                  type="button"
                                  className="btn px-1 py-0 line-height-1 font-size-20 unit-error-icon"
                                >
                                  <i className="icon-caution text-danger"></i>
                                </button>
                              </OverlayTrigger>
                            )}
                          </div>
                          {callRecord.unitAudioUrl && (
                            <div className="">
                              <PlayAudioWaveButton audioLink={callRecord.unitAudioUrl} />
                            </div>
                          )}
                        </div>
                        <p className="mb-3">{callRecord.description}</p>
                      </div>
                      <div className="col-12">
                        {callRecord.descriptionAudioUrl && (
                          <div className="audioPlayer-timeline">
                            <PlayAudioWaveButton audioLink={callRecord.descriptionAudioUrl} />
                          </div>
                        )}
                      </div>
                    </div>

                    <button
                      className={
                        deviceName === DEVICES.TABLET || deviceName === DEVICES.MOBILE
                          ? `btn float-end text-primary font-weight-bold ${
                              updateState === REQUEST_STATUS.LOADING ? 'disabled' : ''
                            }`
                          : 'btn btn-primary font-weight-bold'
                      }
                      type="button"
                      onClick={onCallAction}
                    >
                      {callRecord.isOpen && (
                        <span>
                          Mark as closed
                          <i className="icon-checkmark font-size-lg ms-2 align-middle"></i>
                        </span>
                      )}
                      {!callRecord.isOpen && 'Re-open'}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                {/*call-details-card*/}
                <div className="card call-details-card">
                  <div className="card-body">
                    <h3 className="font-weight-bold mb-3">Call Details</h3>
                    <div className="row">
                      <div className="col-6">
                        <p className="cl-detail__label">Resident Name</p>
                        <p className="cl-detail__text">
                          {callRecord.residentFirstName} {callRecord.residentLastName}
                        </p>
                      </div>
                      <div className="col-6">
                        <p className="cl-detail__label">Call Received On</p>
                        <p className="cl-detail__text">{getMonthDateTime(callRecord.callTime)}</p>
                      </div>
                      <div className="col-6">
                        <p className="cl-detail__label">Resident Phone</p>
                        <p className="cl-detail__text">
                          {getUSFormat(callRecord.residentPhoneNumber)}
                        </p>
                      </div>
                      <div className="col-6">
                        <p className="cl-detail__label">Call Received By</p>
                        <p
                          className="cl-detail__text"
                          style={{
                            color:
                              callRecord?.callEntryStatus === 'answered'
                                ? colorList.black
                                : colorList.gray800,
                          }}
                        >
                          {callRecord?.callEntryStatus === 'answered'
                            ? callRecord.maintenanceUserName
                            : getCallEntryStatusLabel(callRecord?.callEntryStatus)}
                        </p>
                      </div>
                      <div className="col-12">
                        {(deviceName === DEVICES.TABLET || deviceName === DEVICES.MOBILE) && (
                          <div className="col-sm">
                            <button
                              type="button"
                              className="btn w-100 btn-success font-size-lg"
                              onClick={generateSingleUseNumber}
                            >
                              {isMaskingNumber && `Generating single use phone number...`}
                              {!isMaskingNumber && (
                                <span>
                                  <i className="icon-Phone font-size-xl me-2 align-middle"></i>Call
                                  resident
                                </span>
                              )}
                            </button>
                            <Accordion className="msg-accordian my-3">
                              <Accordion.Item eventKey="0">
                                <div className="according-header d-flex justify-content-center align-items-center w-100">
                                  Your phone number won’t be visible to the resident.
                                  <Accordion.Header>
                                    <i className="icon-chevron-down"></i>
                                  </Accordion.Header>
                                </div>
                                <Accordion.Body>
                                  We will create a temporary phone number for this call, which the
                                  resident can also use to call you back. This temporary number will
                                  expire after 1 hour.
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <Accordion className="custom-accordion" defaultActiveKey="1">
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Job Activity
                      <i className="toggle-arrow icon-chevron-right"></i>
                    </Accordion.Header>
                    <Accordion.Body>
                      {callRecord.activityHistoryList && (
                        <ul className="list-group list-group-flush call-activity timeline-view">
                          {callRecord.activityHistoryList.map((history, index) => {
                            return (
                              <li key={index} className="list-group-item">
                                {history.activityType === 'call_received' && (
                                  <div className="call-activity-item d-flex">
                                    <div className="activity-icon">
                                      <i className="icon-log-in text-muted"></i>
                                    </div>
                                    <div className="ms-2">
                                      <p className="font-size-sm mb-0">
                                        <strong>Call Received</strong> from resident named{' '}
                                        <strong>{history.from}</strong>
                                      </p>
                                      <p className="activity-time mb-0">
                                        {getMonthDateYearTime(history.activityTime)}
                                      </p>
                                    </div>
                                  </div>
                                )}
                                {history.activityType === 'work_order_created' && (
                                    <div className="call-activity-item d-flex">
                                      <div className="activity-icon">
                                        <i className="icon-checkmark text-muted"></i>
                                      </div>
                                      <div className="ms-2">
                                        <p className="font-size-sm mb-0">
                                          <strong>Work Oder {history.info} Created</strong> in PMS{' '}
                                          <strong>{history.from}</strong>
                                        </p>
                                        <p className="activity-time mb-0">
                                          {getMonthDateYearTime(history.activityTime)}
                                        </p>
                                      </div>
                                    </div>
                                )}
                                {history.activityType === 'job_call_contacted' &&
                                  history.answerStatus === 'not_answered' && (
                                    <div className="call-activity-item d-flex">
                                      <div className="activity-icon">
                                        <i className="icon-phone-missed text-muted"></i>
                                      </div>
                                      <div className="ms-2">
                                        <p className="font-size-sm mb-0">
                                          <strong>JobCall</strong> contacted{' '}
                                          <strong>
                                            {history.from} {history.callCount}
                                          </strong>{' '}
                                          {history.callCount === 1 ? 'time ' : 'times '}
                                          without answer.
                                        </p>
                                        <p className="activity-time mb-0">
                                          {' '}
                                          {getMonthDateYearTime(history.activityTime)}
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                {history.activityType === 'job_call_contacted' &&
                                  history.answerStatus === 'answered' && (
                                    <div className="call-activity-item d-flex">
                                      <div className="activity-icon">
                                        <i className="icon-phone-incoming text-muted"></i>
                                      </div>
                                      <div className="ms-2">
                                        <p className="font-size-sm mb-0">
                                          <strong>JobCall</strong> contacted{' '}
                                          <strong>
                                            {history.from} {history.callCount}
                                          </strong>{' '}
                                          {history.callCount === 1 ? 'time ' : 'times '}
                                          and answered.
                                        </p>
                                        <p className="activity-time mb-0">
                                          {' '}
                                          {getMonthDateYearTime(history.activityTime)}
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                {history.activityType === 'staff_called' && (
                                  <div className="call-activity-item d-flex">
                                    <div className="activity-icon">
                                      <i className="icon-phone-outgoing text-muted"></i>
                                    </div>
                                    <div className="ms-2">
                                      <p className="font-size-sm mb-0">
                                        <strong>{history.from}</strong> called resident{' '}
                                        {/*is jobcall static here..*/}
                                        <strong>{history.to}</strong> via JobCall
                                      </p>
                                      <p className="activity-time mb-0">
                                        {getMonthDateYearTime(history.activityTime)}
                                      </p>
                                    </div>
                                  </div>
                                )}
                                {history.activityType === 'job_closed' && (
                                  <div className="call-activity-item d-flex">
                                    <div className="activity-icon">
                                      <i className="icon-check-circle text-muted"></i>
                                    </div>
                                    <div className="ms-2">
                                      {history.user !== 'PMS' && (
                                          <p className="font-size-sm mb-0">
                                            <strong>{history.user}</strong> marked the job as{' '}
                                            <strong>Closed.</strong>
                                          </p>
                                      )}
                                      {history.user === 'PMS' && (
                                          <p className="font-size-sm mb-0">
                                            This call was marked as <strong>Closed</strong> in the PMS system.
                                          </p>
                                      )}
                                      <p className="activity-time mb-0">
                                        {' '}
                                        {getMonthDateYearTime(history.activityTime)}
                                      </p>
                                    </div>
                                  </div>
                                )}
                                {history.activityType === 'job_opened' && (
                                  <div className="call-activity-item d-flex">
                                    <div className="activity-icon">
                                      <i className="icon-rotate-ccw text-muted"></i>
                                    </div>
                                    <div className="ms-2">
                                      <p className="font-size-sm mb-0">
                                        <strong>{history.user}</strong> marked the job as{' '}
                                        <strong>Open.</strong>
                                      </p>
                                      <p className="activity-time mb-0">
                                        {' '}
                                        {getMonthDateYearTime(history.activityTime)}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      )}
                      {(!callRecord.activityHistoryList ||
                        callRecord.activityHistoryList.length === 0) &&
                        'Nothing here'}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            {/*JOB history*/}
          </div>
        </div>
        <EditTaskModal
          show={editTaskModal}
          onHide={() => setEditTaskModal(false)}
          closemodal={e => closeEditTaskModal(e)}
          details={editTaskDetails}
          handleupdate={val => updateEditTask(val)}
          setToasterError={setToasterError}
        />
        <ToastMessage
          show={toasterError.show}
          message={toasterError.message}
          type={'danger'}
          onClose={() => {
            setToasterError({ show: false, message: '' });
          }}
        />
      </>
    )
  );
};

export default CallLogDetail;
