import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { syncResmanUnits } from '../../services/dashBoardServices';
import { REQUEST_STATUS } from '../../constants/loading';

const ConfirmationModal = props => {
  const requestUploadInitial = {
    propertyId: '',
    target: 'resman',
  };

  const [requestUploadSpec, setRequestUploadSpec] = useState(requestUploadInitial);

  const handleUploadUnit = async () => {
    props.loadingState(REQUEST_STATUS.LOADING);
    const prevSpec = { ...requestUploadSpec };
    prevSpec.propertyId = props.propertyDetail?.propertyId;
    setRequestUploadSpec(prevSpec);
    const res = await syncResmanUnits(prevSpec);
    if (res.status === 400) {
      props.toast({ show: true, message: res.status.message, bg: 'danger' });
    } else {
      props.toast({ show: true, message: 'Sync Successful', bg: 'success' });
    }
    props.loadingState(REQUEST_STATUS.SUCCESS);
    closeModalOnly();
  };

  const closeModalOnly = () => {
    props.hide();
    props.openModal();
  };

  return (
    <>
      <Modal className="custom-modal no-border" show={props.show} onHide={props.hide} centered>
        <Modal.Header className="justify-content-end">
          <button className="btn p-1" onClick={closeModalOnly}>
            <i className="icon-cross font-size-20"></i>
          </button>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div className="d-flex align-items-center mb-3">
            <i className={`${props.icon} font-size-xl me-2`}></i>
            <h3 className="font-size-28 mb-0">{props.mainTitle}</h3>
          </div>
          <p className="mb-0">{props.subTitle}</p>
        </Modal.Body>
        <Modal.Footer className="py-4">
          <button className="btn btn-light me-2" onClick={closeModalOnly}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={handleUploadUnit}>
            Continue
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
