import React, {useCallback, useContext, useEffect, useState} from 'react';
import Loader from "../../components/Loader/Loader";
import {Dropdown} from "react-bootstrap";
import MultiSelect from "react-multiple-select-dropdown-lite";
import AuthContext from "../../store/AuthContext";
import {fetchPropertyList, fetchPropertyStats} from "../../services/dashBoardServices";
import {REQUEST_STATUS} from "../../constants/loading";
import {getMonthLabel, getYearList} from "../../helpers/helpers";
import ToastMessage from "../../components/ToastMessage/ToastMessage";

const searchRequestInitial = {
    year: new Date().getFullYear(),
    month: (new Date().getMonth() + 1).toString().padStart(2, '0')
};

const errorInitial = {
    propertyIds: false,
};

const requiredFields = [
    {name: 'year', type: ''},
    {name: 'month', type: ''},
    {name: 'propertyIds', type: 'multiselect'},
];

const toastInitial = {
    type: 'danger',
    show: false,
    message: ''
};

const yearList = getYearList();

export default function PropertyStatReport() {

    const authContext = useContext(AuthContext);
    const [isLoading, setLoading] = useState(false);
    const [errors, setErrors] = useState(errorInitial);
    const [searchRequest, setSearchRequest] = useState(searchRequestInitial);
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [propertyList, setPropertyList] = useState([]);
    const [propertyListState, setPropertyListState] = useState(REQUEST_STATUS.IDLE);
    const [canSubmit, setCanSubmit] = useState(false);
    const [reportLoadState, setReportLoadState] = useState(REQUEST_STATUS.IDLE);
    const [toast, setToast] = useState(toastInitial);

    const getPropertyList = useCallback(async () => {
        const userPar = authContext.user;
        const propList = await fetchPropertyList(userPar?.id);
        const propListOptions = propList.propertyList.map(item => ({
            label: item.propertyName,
            value: item.propertyId,
        }));
        setPropertyList(propListOptions);
        setPropertyListState(REQUEST_STATUS.SUCCESS);
    }, [authContext.user]);

    const closeToast = () => {
        setToast(prevState => ({
            ...prevState,
            show: false,
        }));
    };

    const updateSearchRequestVal = (value, field, type) => {
        let updateValue = value;
        if (type === 'multiselect') {
            let error = false;
            if (value === undefined) {
                error = true;
            } else if (typeof value === 'string') {
                updateValue = value.split(',');
                error = updateValue.length === 1 && value === '';
            } else {
                error = updateValue.length < 1;
            }
            setErrors(prevErrors => ({
                ...prevErrors,
                [field]: error,
            }));
        } else {
            setErrors(prevErrors => ({
                ...prevErrors,
                [field]: updateValue.length < 1,
            }));
        }
        setSearchRequest(prevUser => ({
            ...prevUser,
            [field]: updateValue,
        }));
    };

    useEffect(() => {
        if (propertyListState === REQUEST_STATUS.IDLE) {
            setPropertyListState(REQUEST_STATUS.PENDING);
        }
        if (propertyListState === REQUEST_STATUS.PENDING) {
            setPropertyListState(REQUEST_STATUS.LOADING);
            getPropertyList();
        }
    }, [getPropertyList, propertyListState]);


    const handleSubmit = async e => {
        e.preventDefault();
        requiredFields.map(field => {
            updateSearchRequestVal(searchRequest[field.name], field.name, field.type);
        });
        setCanSubmit(true);
    };

    const reportDownload = useCallback(async () => {
        let hasError = false;
        Object.keys(errors).map(key => {
            if (errors[key]) {
                hasError = true;
            }
        });

        if (hasError) {
            setToast({
                type: 'danger',
                show: true,
                message: 'Search Data Invalid!',
            });
        } else {
            setReportLoadState(REQUEST_STATUS.LOADING);
            const res = await fetchPropertyStats(searchRequest);
            if (res.status === 200) {
                const url = URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.download = 'property-stats-report.csv';
                link.click();
                URL.revokeObjectURL(url);
                setToast({
                    show: true,
                    message: 'CSV File downloaded successfully!',
                    type: 'success',
                });
            }
            const resErr = res?.status && res?.status !== 200;
            let type = 'danger';
            let message = res.data?.message ? res.data?.message : 'Failed to download CSV file';
            if (res?.status && res?.status === 404) {
                message = 'No data found for the given search criteria';
                type = 'warning';
            }
            if (resErr) {
                setToast({
                    show: true,
                    message: message,
                    type: type,
                });
            }
            setReportLoadState(REQUEST_STATUS.SUCCESS);
            resetSearchRequest();
        }
    }, [errors, setReportLoadState, setToast, searchRequest]);


    const resetSearchRequest = () => {
        setSearchRequest(searchRequestInitial);
        setSelectedProperties([]);
        setErrors(errorInitial);
    };

    useEffect(() => {
        if (canSubmit) {
            setCanSubmit(false);
            reportDownload();
        }
    }, [canSubmit, errors, reportDownload]);

    return (
        <>
            <Loader
                isLoading={
                    isLoading ||
                    reportLoadState === REQUEST_STATUS.LOADING
                }
            />
            <div className="header mb-3">
                <div className="container">
                    <div className="mb-4">
                        <label htmlFor="year" className="form-label">
                            Year <span className="text-danger">*</span>
                        </label>
                        <Dropdown
                            className="custom-dropdown"
                            onSelect={val => updateSearchRequestVal(val, 'year')}
                        >
                            <Dropdown.Toggle className="outline-toggle" variant="link">
                                {searchRequest.year}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="full-width">
                                {
                                    [...yearList].map(item => (
                                        <Dropdown.Item key={item} eventKey={item}>{item}</Dropdown.Item>
                                    ))
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="month" className="form-label">
                            Month <span className="text-danger">*</span>
                        </label>
                        <Dropdown
                            className="custom-dropdown"
                            onSelect={val => updateSearchRequestVal(val, 'month')}
                        >
                            <Dropdown.Toggle className="outline-toggle" variant="link">
                                {getMonthLabel(searchRequest.month)}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="full-width">
                                <Dropdown.Item eventKey="01">January</Dropdown.Item>
                                <Dropdown.Item eventKey="02">February</Dropdown.Item>
                                <Dropdown.Item eventKey="03">March</Dropdown.Item>
                                <Dropdown.Item eventKey="04">April</Dropdown.Item>
                                <Dropdown.Item eventKey="05">May</Dropdown.Item>
                                <Dropdown.Item eventKey="06">June</Dropdown.Item>
                                <Dropdown.Item eventKey="07">July</Dropdown.Item>
                                <Dropdown.Item eventKey="08">August</Dropdown.Item>
                                <Dropdown.Item eventKey="09">September</Dropdown.Item>
                                <Dropdown.Item eventKey="10">October</Dropdown.Item>
                                <Dropdown.Item eventKey="11">November</Dropdown.Item>
                                <Dropdown.Item eventKey="12">December</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="mb-5">
                        <label htmlFor="propertyList" className="form-label">
                            Property <span className="text-danger">*</span>
                        </label>
                        <MultiSelect
                            className="custom-multiple-select"
                            onChange={val => {
                                updateSearchRequestVal(val, 'propertyIds', 'multiselect');
                            }}
                            placeholder="Choose properties"
                            options={propertyList}
                            defaultValue={selectedProperties}
                        />
                        {errors?.propertyIds && <span className="text-danger">Property required</span>}
                    </div>
                    <div className="d-flex justify-content-end align-items-center">
                        <button
                            className="btn btn-outline-dark px-5 me-3"
                            type="button"
                            onClick={() => {
                                resetSearchRequest();
                            }}
                        >
                            Cancel
                        </button>

                        <button
                            className="btn btn-primary px-5"
                            type="submit"
                            autoFocus={true}
                            onClick={handleSubmit}
                        >
                            Download
                        </button>
                    </div>
                </div>
            </div>
            <ToastMessage
                show={toast.show}
                message={toast.message}
                type={toast.type}
                onClose={closeToast}
            />
        </>
    );
};