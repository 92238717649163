import * as auth0 from 'auth0-js';
import { authConfig } from '../config/auth';
import { USER_ROLES } from '../constants/roles';
import { loggedInUser } from './dashBoardServices';
import { updateAuthData } from './localServices';

const auth = new auth0.WebAuth({
  domain: authConfig.domain,
  clientID: authConfig.clientId,
  responseType: 'token id_token',
  redirectUri: authConfig.redirectUri,
  prompt: 'login',
  scope: 'openid profile email'
});

class AuthInfo {
  expiresAt;
  userProfile;
  accessToken;
  accessPayload;
  authenticated = false;
  isUserFound = false;
}

let authData = {};

export async function getAccessToken() {
  return new Promise((resolve, reject) => {
    auth.checkSession({}, async (err, authResult) => {
      if (authResult && authResult.accessToken) {
        if (err) reject(err);
        resolve(getUserInfo(authResult));
      } else {
        resolve();
      }
    });
  });
}

function getUserInfo(authResult) {
  return new Promise((resolve, reject) => {
    auth.client.userInfo(authResult.accessToken, (err, profile) => {
      if (profile) {
        if (err) reject(err);
        resolve(_setSession(authResult, profile));
      }
    });
  });
}

function _setSession(authResult, profile) {
  authData = new AuthInfo();
  authData.expiresAt = authResult.idTokenPayload.exp * 1000;
  authData.accessToken = authResult.idToken;
  authData.accessPayload = authResult.idTokenPayload;
  authData.userProfile = profile;
  authData.authenticated = true;
  authData.isUserFound = false;
  updateAuthData(authData);
}

export function sendUserValidationCode(contact, mode = 'sms') {
  return new Promise((resolve, reject) => {
    const req = {
      connection: mode,
      send: 'code',
    };
    if (mode === 'email') {
      req.email = contact;
    } else if (mode === 'sms') {
      req.phoneNumber = contact;
    }
    auth.passwordlessStart(req, (err, res) => {
      if (err) reject(err);
      resolve(res);
    });
  });
}

export function loginWithUserValidationCode(code, contact, mode = 'sms') {
  return new Promise((resolve, reject) => {
    const req = {
      connection: mode,
      verificationCode: code,
    };
    if (mode === 'email') {
      req.email = contact;
    } else if (mode === 'sms') {
      req.phoneNumber = contact;
    }
    auth.passwordlessVerify(req, (err, res) => {
      if (err) reject(err);
      resolve(res);
    });
  });
}

export function isLoggedIn() {
  authData = JSON.parse(localStorage.getItem('auth'));
  return authData?.accessToken && Date.now() < authData?.expiresAt;
}

export function logout(isUserNotFound) {
  auth.logout({
    returnTo: `${window.location.origin}`,
    clientID: authConfig.clientId,
  });
  authData.authenticated = false;
  authData.accessToken = null;
  localStorage.clear();
  authData.isUserNotFound = isUserNotFound;
}

export function handleLoginCallback() {
  return new Promise((resolve, reject) => {
    auth.parseHash((err, authResult) => {
      if (authResult && authResult.idToken) {
        _setSession(authResult, null);
        resolve(true);
      } else if (err) {
        console.error(`Error: ${err.error}`);
      }
      resolve(false);
    });
  });
}

export function isUserSysAdmin() {
  let user = JSON.parse(localStorage.getItem('user'));
  return user?.userRole === USER_ROLES.SYSTEM_ADMIN;
}

export async function fetchLoggedInUser() {
  const userDetails = await loggedInUser();
  localStorage.setItem('user', JSON.stringify(userDetails));
  return userDetails;
}

export const Account_Not_Found_Key = 'Account_Not_Found';
