import { createContext } from 'react';

const AuthContext = createContext({
  user: null,
  isLoggedIn: false,
  isSysAdmin: false,
  isReadyApp: false,
});

export default AuthContext;
