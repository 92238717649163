export const colorList = {
  grayLight: '#f9fbfd',
  grayLight100: '#f9fafc',
  grayDark02: '#636366',
  boxShadow: '#1f2d3d',

  //dashkit
  white: '#ffffff',
  gray100: '#f9fbfd',
  gray200: '#edf2f9',
  gray300: '#e3ebf6',
  gray400: '#d2ddec',
  gray500: '#b1c2d9',
  gray600: '#95aac9',
  gray700: '#6e84a3',
  gray800: '#3b506c',
  gray900: '#283e59',
  black: '#12263f',

  gray600Dark: '#244166',
  gray700Dark: '#1e3a5c',
  gray800Dark: '#152e4d',
  gray900Dark: '#132a46',
  blackDark: '#12263f',

  blue: '#2c7be5',
  indigo: '#727cf5',
  purple: '#6b5eae',
  pink: '#ff679b',
  red: '#e63757',
  orange: '#fd7e14',
  yellow: '#f6c343',
  green: '#00d97e',
  teal: '#02a8b5',
  cyan: '#39afd1',

  primary: '$blue',
  secondary: '$gray',
  success: '$green',
  info: '$cyan',
  warning: '$yellow',
  danger: '$red',
  light: '$gray',
  dark: '$black',
};
