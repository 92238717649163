import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as FullStory from '@fullstory/browser';

const root = ReactDOM.createRoot(document.getElementById('root'));
FullStory.init(
	{
	  orgId: 'o-1CZ4ZR-na1',
	  recordCrossDomainIFrames: true,
	  devMode: window.location.hostname !== 'app.jobcall.com',
	},
	({ sessionUrl }) => console.log(`Started session: ${sessionUrl}`)
  );
root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
