import React, { useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import {
  fetchLoggedInUser,
  getAccessToken,
  handleLoginCallback,
  isLoggedIn,
  isUserSysAdmin,
} from './services/authServices';
import { getAuthData, getLoginedUser } from './services/localServices';
import ProtectedRoutes from './routes/ProtectedRoutes';
import AuthContext from './store/AuthContext';
import { Login } from './pages/Login/Login';
import AppLoading from './components/AppLoading/AppLoading';
import HeaderLayout from './components/HeaderLayout/HeaderLayout';
import UnAuthorize from './pages/UnAuthorize/UnAuthorize';
import WeeklySettings from './pages/CallSettings/WeeklySettings';
import CallLogDetail from './pages/CallLog/CallLogDetail';
import CallLog from './pages/CallLog/CallLog';
import PropertyList from './pages/Property/PropertyList';
import UserList from './pages/Users/UserList';
import PropertyStatReport from "./pages/Report/PropertyStatReport";
import './App.scss';
import mixPanelService from './services/mixPanelService';

import { getRoleLabel } from './helpers/helpers';
import UserRegistration from "./pages/UserRegistration/UserRegistration";
import CallMultiLog from "./pages/CallLog/CallMultiLog";
import {Analytics} from "./pages/Analytics/Analytics";
import * as FullStory from "@fullstory/browser";

const App = () => {
  const [isLoadingApp, setIsLoadingApp] = useState(false);
  const [isReadyApp, setIsReadyApp] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);
  const [user, setUser] = useState(null);
  const [isSysAdmin, setIsSysAdmin] = useState(false);

  useEffect(() => {
    if (!isLoadingApp && !isReadyApp) {
      setIsLoadingApp(true);
    }
    if (isLoadingApp && !isReadyApp) {
      initApp();
    }
  }, [isLoadingApp, isReadyApp]);

  useEffect(() => {
    if (isReadyApp) {
      setIsLoadingApp(false);
    }
  }, [isReadyApp]);

  const initApp = async () => {
    if (!isLoggedIn()) {
      if (window.location.hash) {
        await handleLoginCallback();
      }
      if (getAuthData()) {
        await getAccessToken();
      }
      const loggedIn = isLoggedIn();
      if (loggedIn) {
        const user = await fetchLoggedInUser();
        setUser(user);
        FullStory.identify(user.id);
        mixPanelService.people.set(user.id, {
          contactNumber: user.contactNumber,
          $email: user.email,
          $first_name: user.firstName,
          $last_name: user.lastName,
          isPushedToAuthZero: user.isPushedToAuthZero,
          preferredLanguage: user.preferredLanguage,
          userRole: getRoleLabel(user.userRole),
        });
        setShowDashboard(true);
      }
    } else {
      const userData = getLoginedUser();
      if (!userData) {
        setUser(await fetchLoggedInUser());
      } else {
        setUser(userData);
        FullStory.identify(userData.id);
      }
      setShowDashboard(true);
    }
    setIsSysAdmin(await isUserSysAdmin());
    setIsReadyApp(true);
  };

  if (!isReadyApp || isLoadingApp) {
    return <AppLoading fadeAway={false} />;
  }

  return (
    <AuthContext.Provider
      value={{
        user: user,
        isSysAdmin: isSysAdmin,
        isReadyApp: isReadyApp,
      }}
    >
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <div className="App">
              {showDashboard && <Redirect to="/call-log" />}
              {!showDashboard && user == null && <Login />}
            </div>
          </Route>
          <Route exact path="/user-registration/:id" component={UserRegistration} />
          <Route exact path="/un-authorize" component={UnAuthorize} />
          <ProtectedRoutes
            exact
            path="/properties"
            component={() => <HeaderLayout sub={PropertyList} />}
          />
          <ProtectedRoutes
            path="/users"
            component={() => <HeaderLayout sub={UserList} />}
          ></ProtectedRoutes>
          <ProtectedRoutes
            path="/call-settings"
            component={() => <HeaderLayout sub={WeeklySettings} />}
          ></ProtectedRoutes>
          <ProtectedRoutes
            path="/call-log/:id"
            component={() => <HeaderLayout sub={CallLogDetail} />}
          ></ProtectedRoutes>
          <ProtectedRoutes
            path="/call-log"
            component={() => <HeaderLayout sub={isSysAdmin ? (CallMultiLog) : (CallMultiLog)} />}
          ></ProtectedRoutes>
          <ProtectedRoutes
              path="/property-stat-report"
              component={() => <HeaderLayout sub={PropertyStatReport} />}
          ></ProtectedRoutes>
          <ProtectedRoutes
              path="/analytics"
              component={() => <HeaderLayout sub={Analytics} />}
          ></ProtectedRoutes>
        </Switch>
      </BrowserRouter>
    </AuthContext.Provider>
  );
};

export default App;
