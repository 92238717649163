import React, { useContext, useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AppLoading from '../components/AppLoading/AppLoading';
import AuthContext from '../store/AuthContext';

const ProtectedRoutes = ({ component: Component, ...res }) => {
  const authContext = useContext(AuthContext);

  const [fadeAway, setFadeAway] = useState(false);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    if (authContext.isReadyApp) {
      setTimeout(() => {
        setFadeAway(true);
      }, 300);
    }
  }, [authContext.isReadyApp]);

  useEffect(() => {
    if (fadeAway) {
      setTimeout(() => {
        setShowLoader(false);
      }, 500);
    }
  }, [fadeAway]);

  return (
    <Route
      {...res}
      render={props => {
        if (authContext.isReadyApp && !authContext.user) {
          return <Redirect to={{ path: '/', state: { from: props.location } }} />;
        }
        return (
          <>
            {showLoader && <AppLoading fadeAway={fadeAway} />}
            <Component {...props} />
          </>
        );
      }}
    />
  );
};

export default ProtectedRoutes;
