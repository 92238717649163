import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

export default function NotificationModal({ show, onHide, user, data, onSubmit }) {
  const [notificationForm, setNotificationForm] = useState({
    id: data?.id,
    userId: data?.userId,
    assignedEmail: !!data?.assignedEmail,
    assignedSms: !!data?.assignedSms,
    closedEmail: !!data?.closedEmail,
    closedSms: !!data?.closedSms,
    newRequestEmail: !!data?.newRequestEmail,
    newRequestSms: !!data?.newRequestSms,
    unansweredEmail: !!data?.unansweredEmail,
    unansweredSms: !!data?.unansweredSms,
  });

  useEffect(() => {
    setNotificationForm({
      id: data?.id,
      userId: data?.userId,
      assignedEmail: !!data?.assignedEmail,
      assignedSms: !!data?.assignedSms,
      closedEmail: !!data?.closedEmail,
      closedSms: !!data?.closedSms,
      newRequestEmail: !!data?.newRequestEmail,
      newRequestSms: !!data?.newRequestSms,
      unansweredEmail: !!data?.unansweredEmail,
      unansweredSms: !!data?.unansweredSms,
    });
  }, [data]);

  const handleChange = (field, val) => {
    setNotificationForm(prevState => ({
      ...prevState,
      [field]: val,
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit(notificationForm);
  };

  return (
    <>
      <Modal className="notification-modal" show={show} onHide={onHide} centered>
        <Modal.Header>
          <h2 className="mb-0">{`Notification for ${user?.name}`}</h2>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <table className="table">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>SMS</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>New request received</td>
                  <td>
                    <Form.Check
                      type="checkbox"
                      name="new_req_sms"
                      checked={notificationForm.newRequestSms}
                      onChange={event => handleChange('newRequestSms', event.target.checked)}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="checkbox"
                      name="new_req_email"
                      checked={notificationForm.newRequestEmail}
                      onChange={event => handleChange('newRequestEmail', event.target.checked)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Request assigned to teammate</td>
                  <td>
                    <Form.Check
                      type="checkbox"
                      name="req_assigned_sms"
                      checked={notificationForm.assignedSms}
                      onChange={event => handleChange('assignedSms', event.target.checked)}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="checkbox"
                      name="req_assigned_email"
                      checked={notificationForm.assignedEmail}
                      onChange={event => handleChange('assignedEmail', event.target.checked)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Request unanswered by team</td>
                  <td>
                    <Form.Check
                      type="checkbox"
                      name="req_missed_sms"
                      checked={notificationForm.closedSms}
                      onChange={event => handleChange('closedSms', event.target.checked)}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="checkbox"
                      name="req_missed_email"
                      checked={notificationForm.closedEmail}
                      onChange={event => handleChange('closedEmail', event.target.checked)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Request marked as closed</td>
                  <td>
                    <Form.Check
                      type="checkbox"
                      name="req_closed_sms"
                      checked={notificationForm.unansweredSms}
                      onChange={event => handleChange('unansweredSms', event.target.checked)}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="checkbox"
                      name="req_closed_email"
                      checked={notificationForm.unansweredEmail}
                      onChange={event => handleChange('unansweredEmail', event.target.checked)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="d-flex justify-content-end">
              <Button variant="" className="me-3" onClick={onHide}>
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
