import axios from 'axios';
import config from './app';

// const host = window.location.hostname;
let token = '';

const baseApi = () => {
  console.log('config.apiUrl : '+config.apiUrl)
  if (!token.length) {
    let authData = JSON.parse(localStorage.getItem('auth'));
    if(authData != null){
      token = authData?.accessToken;
    }
  }
  return axios.create({
    headers: { Authorization: `Bearer ${token}` },
    baseURL: config.apiUrl,
  });
};

export default baseApi;
