import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Dropdown, Image, Nav, Tab} from 'react-bootstrap';
import MultiSelect from 'react-multiple-select-dropdown-lite';
import DatePicker, {DateObject} from 'react-multi-date-picker';
import {
  fetchCallsList,
  fetchPropertyList,
  maskPhoneNumber,
  openCloseMultipleJobs,
  updateAction,
  updateCallTask,
} from '../../services/dashBoardServices';
import AuthContext from '../../store/AuthContext';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import {REQUEST_STATUS} from '../../constants/loading';
import {DEVICES} from '../../constants/devices';
import Loader from '../../components/Loader/Loader';
import {getDefaultFormat, getSortByClassName} from '../../helpers/helpers';
import {getSelectedProperty, updateSelectedProperty} from '../../services/localServices';
import PropertySwitcher from '../../components/PropertySwitcher/PropertySwitcher';
import ToastMessage from '../../components/ToastMessage/ToastMessage';
import EditTaskModal from '../../components/CallLog/EditTaskModal';
import CallListMobile from '../../components/CallLog/CallListMobile';
import CallListDesktop from '../../components/CallLog/CallListDesktop';
import CallListPagination from '../../components/CallLog/CallListPagination';
import maxPanelService from '../../services/mixPanelService';

import IconAngleDown from '../../assets/images/chevron-down.svg';
import IconClose from '../../assets/images/close.svg';

const pageSize = 20;

const sharedListInitialState = {
  sortColumn: 'callTime',
  sortDirection: 'DESC',
  maintenanceIssue: null,
  dateRange: [new DateObject().add(-1, 'months'), new DateObject()],
  status: 'open',
};

const callsInitialState = {
  offset: 0,
  pageSize: pageSize,
  currentPage: 1,
  maxPage: 1,
  totalRecords: 0,
};

const editTaskInitial = {
  unit: '',
  residentFirstName: '',
  residentPhoneNumber: '',
  maintenanceIssue: '',
  description: '',
  id: '',
  status: '',
  callType: '',
};

const issueTypes = [
  {
    value: 'electrical',
    label: 'Electrical',
  },
  {
    value: 'plumbing',
    label: 'Plumbing',
  },
  {
    value: 'heating',
    label: 'Heating',
  },
  {
    value: 'cooling',
    label: 'Cooling',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

const CallLog = () => {
  const [callType, setCallType] = useState('emergency');
  const [statusFilter, setStatusFilter] = useState('open');
  const [selectedProperty, setSelectedProperty] = useState({});
  const [propertyLists, setPropertyLists] = useState([]);
  const [propertyListState, setPropertyListState] = useState(REQUEST_STATUS.IDLE);
  const [closeAction] = useState({ updateAction: 'close' });
  const [openAction] = useState({ updateAction: 'open' });
  const [toasterError, setToasterError] = useState({ show: false, message: '' });
  const [, deviceName] = useWindowDimensions();
  const authContext = useContext(AuthContext);
  // initial query for all open calls
  const [callLogsState, setCallLogsState] = useState(REQUEST_STATUS.IDLE);
  const [selectedJobs, setSelectedJobs] = useState({ open: [], close: [] });
  const [sharedListSpec, setSharedListSpec] = useState(sharedListInitialState);

  //open calls
  const [openCalls, setOpenCalls] = useState({});
  const [openCallsState, setOpenCallsState] = useState(REQUEST_STATUS.IDLE);
  const [openCallsListSpec, setOpenCallsSpec] = useState({
    ...callsInitialState,
    callType: 'emergency',
  });
  //closed calls
  const [closedCalls, setClosedCalls] = useState({});
  const [closedCallsState, setClosedCallsState] = useState(REQUEST_STATUS.IDLE);
  const [closedCallsListSpec, setClosedCallsSpec] = useState({
    ...callsInitialState,
    callType: 'maintenance',
  });
  // General
  const [dateRage, setDateRage] = useState({
    initial: `${sharedListInitialState.dateRange[0].format(
      'YYYY-MM-DD'
    )} - ${sharedListInitialState.dateRange[1].format('YYYY-MM-DD')}`,
    current: `${sharedListInitialState.dateRange[0].format(
      'YYYY-MM-DD'
    )} - ${sharedListInitialState.dateRange[1].format('YYYY-MM-DD')}`,
  });
  const dateFilterRef = useRef();
  const [isLoading, setLoading] = useState(false);
  //Generate single use phone numbers
  const [buttonTransition, setButtonTransition] = useState(false);
  const [btnTransId, setBtnTransId] = useState();

  //Edit task modal
  const [editTaskModal, setEditTaskModal] = useState(false);
  const [editTaskDetails, setEditTaskDetails] = useState(editTaskInitial);

  const getFilterDate = () => {
    const dateRange = sharedListSpec.dateRange;
    let dateString = 'Call Date';
    if (dateRange.length === 1) {
      dateString = `${dateRange[0].format('MMM DD')} - ${dateRange[0].format('MMM DD')}`;
    } else if (dateRange.length === 2) {
      dateString = `${dateRange[0].format('MMM DD')} - ${dateRange[1].format('MMM DD')}`;
    }
    return dateString;
  };

  const getPropertyList = useCallback(async () => {
    const userPar = authContext.user;
    const propList = await fetchPropertyList(userPar?.id);
    setPropertyLists(propList.propertyList);

    let selectedProp = getSelectedProperty();
    if (!selectedProp?.propertyId) {
      selectedProp = propList.propertyList[0];
      updateSelectedProperty(selectedProp);
    }
    setSelectedProperty(selectedProp);
  }, [authContext.user]);

  const transformListSpec = useCallback(
    ({ currentPage, pageSize, status, sortColumn, sortDirection, dateRange, maintenanceIssue, callType }) => {
      const payload = {
        offset: (currentPage - 1) * pageSize,
        pageSize,
        status,
        propertyIds: [selectedProperty.propertyId],
        sortColumn,
        sortDirection,
        dateRange: {
          fromDate: dateRange[0]?.format(),
          toDate: dateRange[1] ? dateRange[1]?.format() : dateRange[0]?.format(),
        },
        issueTypes: maintenanceIssue,
        callType: callType,
      };
      return payload;
    },
    [selectedProperty.propertyId]
  );

  useEffect(() => {
    // Scroll to top when page loads
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (propertyListState === REQUEST_STATUS.IDLE && callLogsState === REQUEST_STATUS.IDLE) {
      setPropertyListState(REQUEST_STATUS.PENDING);
      setCallLogsState(REQUEST_STATUS.IDLE);
    }
  }, [propertyListState, callLogsState]);

  useEffect(() => {
    const loadAsync = async () => {
      if (propertyListState === REQUEST_STATUS.PENDING) {
        setPropertyListState(REQUEST_STATUS.LOADING);
        await getPropertyList();
        setPropertyListState(REQUEST_STATUS.SUCCESS);
        setCallLogsState(REQUEST_STATUS.PENDING);
      }
    };
    loadAsync();
  }, [getPropertyList, propertyListState]);

  useEffect(() => {
    /* Should load initially and when selected property change */
    const loadAsync = async () => {
      if (callLogsState === REQUEST_STATUS.PENDING) {
        setCallLogsState(REQUEST_STATUS.LOADING);
        // Reset states
        setPropertyListState(REQUEST_STATUS.IDLE);
        setOpenCallsState(REQUEST_STATUS.IDLE);
        setClosedCallsState(REQUEST_STATUS.IDLE);

        const openCalls = await fetchCallsList(
          transformListSpec({ ...openCallsListSpec, ...sharedListSpec })
        );
        setOpenCallsSpec({
          ...openCallsListSpec,
          totalRecords: openCalls.totalRecords,
          maxPage: Math.max(Math.ceil(openCalls.totalRecords / pageSize), 1),
        });
        setOpenCalls(openCalls);

        const closedCalls = await fetchCallsList(
          transformListSpec({ ...closedCallsListSpec, ...sharedListSpec })
        );

        setClosedCallsSpec({
          ...closedCallsListSpec,
          totalRecords: closedCalls.totalRecords,
          maxPage: Math.max(Math.ceil(closedCalls.totalRecords / pageSize), 1),
        });
        setClosedCalls(closedCalls);
        setCallLogsState(REQUEST_STATUS.SUCCESS);
      }
    };
    loadAsync();
  }, [
    callLogsState,
    closedCallsListSpec,
    openCallsListSpec,
    selectedProperty,
    sharedListSpec,
    transformListSpec,
  ]);

  useEffect(() => {
    const loadAsync = async () => {
      if (openCallsState === REQUEST_STATUS.PENDING) {
        setOpenCallsState(REQUEST_STATUS.LOADING);

        const openCalls = await fetchCallsList(
          transformListSpec({ ...openCallsListSpec, ...sharedListSpec })
        );
        setOpenCallsSpec(prevState => ({
          ...prevState,
          totalRecords: openCalls.totalRecords,
          maxPage: Math.ceil(openCalls.totalRecords / pageSize),
        }));
        setOpenCalls(openCalls);
        setOpenCallsState(REQUEST_STATUS.SUCCESS);
      }
    };
    loadAsync();
  }, [openCallsListSpec, openCallsState, editTaskDetails, transformListSpec, sharedListSpec]);

  useEffect(() => {
    const loadAsync = async () => {
      if (closedCallsState === REQUEST_STATUS.PENDING) {
        setClosedCallsState(REQUEST_STATUS.LOADING);

        const closedCalls = await fetchCallsList(
          transformListSpec({ ...closedCallsListSpec, ...sharedListSpec })
        );
        setClosedCallsSpec(prevState => ({
          ...prevState,
          totalRecords: closedCalls.totalRecords,
          maxPage: Math.ceil(closedCalls.totalRecords / pageSize),
        }));
        setClosedCalls(closedCalls);
        setClosedCallsState(REQUEST_STATUS.SUCCESS);
      }
    };
    loadAsync();
  }, [closedCallsListSpec, closedCallsState, sharedListSpec, transformListSpec]);

  const getSortByClass = useCallback(
    (isOpen, sortColumn) => {
      return getSortByClassName(sharedListSpec, sortColumn);
    },
    [sharedListSpec]
  );

  const fetchCallsSort = async (isOpen, sortColumn) => {
    // const setListSpec = isOpen ? setOpenCallsSpec : setClosedCallsSpec;
    // const setCallState = isOpen ? setOpenCallsState : setClosedCallsState;

    setSharedListSpec(prevState => {
      const sortDirection =
        prevState.sortColumn === sortColumn && prevState.sortDirection === 'ASC' ? 'DESC' : 'ASC';
      return {
        ...prevState,
        sortColumn,
        sortDirection,
      };
    });

    setOpenCallsSpec(prevState => {
      return {
        ...prevState,
        currentPage: 1,
        offset: 0,
      };
    });
    setClosedCallsSpec(prevState => {
      return {
        ...prevState,
        currentPage: 1,
        offset: 0,
      };
    });

    setCallLogsState(REQUEST_STATUS.PENDING);
  };

  const handleMultiSelAction = () => {
    setLoading(true);
    const typeKey = statusFilter === 'open' || statusFilter === 'archive' ? 'open' : 'close';
    const action = statusFilter === 'open' || statusFilter === 'archive' ? 'close' : 'open';
    openCloseMultipleJobs(action, selectedJobs[typeKey]).then(res => {
      if (res.data.status === 'Success') {
        setSelectedJobs(prevState => ({
          ...prevState,
          [typeKey]: [],
        }));
        setPropertyListState(REQUEST_STATUS.PENDING);
      } else {
        // Handle error
      }
      setLoading(false);
    });
  };

  const onFilterIssue = value => {
    let issue = null;
    if (value !== '') {
      issue = value.split(',');
    }

    maxPanelService.track('Filter', {
      Page: 'Call Log',
      Name: 'Issue Type',
      Value: issue,
    });
    setOpenCallsSpec(prevSpec => ({
      ...prevSpec,
      currentPage: 1,
    }));
    setClosedCallsSpec(prevSpec => ({
      ...prevSpec,
      currentPage: 1,
    }));
    setSharedListSpec(prevState => ({
      ...prevState,
      maintenanceIssue: issue,
    }));
    setCallLogsState(REQUEST_STATUS.PENDING);
  };

  const onFilterStatus = value => {
    setStatusFilter(value);
    setOpenCallsSpec(prevSpec => ({
      ...prevSpec,
      currentPage: 1,
    }));
    setClosedCallsSpec(prevSpec => ({
      ...prevSpec,
      currentPage: 1,
    }));
    setSharedListSpec(prevState => ({
      ...prevState,
      status: value,
    }));
    setCallLogsState(REQUEST_STATUS.PENDING);
  };

  const onFilterDateRange = value => {
    let dateRange = value;
    if (!value) {
      dateRange = sharedListInitialState.dateRange;
    }

    setOpenCallsSpec(prevSpec => ({
      ...prevSpec,
      currentPage: 1,
    }));
    setClosedCallsSpec(prevSpec => ({
      ...prevSpec,
      currentPage: 1,
    }));
    setSharedListSpec(prevSpec => ({
      ...prevSpec,
      dateRange,
    }));
    let currentArr = dateRange.length === 1 ? [...dateRange, ...dateRange] : dateRange;
    const currentDate = `${currentArr[0].format('YYYY-MM-DD')} - ${currentArr[1].format(
      'YYYY-MM-DD'
    )}`;
    setDateRage(prevState => ({
      ...prevState,
      current: currentDate,
    }));
    maxPanelService.track('Filter', {
      Page: 'Call Log',
      Name: 'Date Range',
      Value: currentDate,
    });
    setCallLogsState(REQUEST_STATUS.PENDING);
  };

  const featchCallsPage = async (callType, page, reload = false) => {
    let setListSpec = null;
    let setCallState = null;
    if(typeof callType === 'boolean'){
      setListSpec = callType ? setOpenCallsSpec : setClosedCallsSpec;
      setCallState = callType ? setOpenCallsState : setClosedCallsState;
    }else{
      setListSpec = callType === 'emergency' ? setOpenCallsSpec : setClosedCallsSpec;
      setCallState = callType === 'emergency' ? setOpenCallsState : setClosedCallsState;
    }
    setListSpec(prevState => {
      const pageNo = page - 1;
      if ((pageNo < 0 || pageNo >= prevState.maxPage) && !reload) {
        return;
      }
      if (page === prevState.currentPage && !reload) {
        return;
      }

      return {
        ...prevState,
        currentPage: page,
        offset: pageNo * pageSize,
      };
    });
    setCallState(REQUEST_STATUS.PENDING);
  };

  const callAction = async (id, action) => {
    setLoading(true);
    await updateAction(id, action);
    let currentPageOpen = openCallsListSpec.currentPage;
    if (currentPageOpen > 1 && openCalls?.list?.length === 1 && action === closeAction) {
      currentPageOpen -= 1;
    }
    await featchCallsPage(true, currentPageOpen, true);

    let currentPageClosed = closedCallsListSpec.currentPage;
    if (currentPageClosed > 1 && closedCalls?.list?.length === 1 && action === openAction) {
      currentPageClosed -= 1;
    }
    await featchCallsPage(false, currentPageClosed, true);
  };

  const handleSelectProperty = selectedProp => {
    updateSelectedProperty(selectedProp);
    const newProps = {
      propertyIds: [selectedProp],
      currentPage: 1,
      offset: 0,
    };

    maxPanelService.track('Select Property', {
      Page: 'Call Log',
      Value: selectedProp,
    });

    const sharedProps = {
      maintenanceIssue: callsInitialState.maintenanceIssue,
      dateRange: sharedListInitialState.dateRange,
    };
    setSharedListSpec(prevState => ({ ...prevState, ...sharedProps }));

    setDateRage(prevState => ({
      ...prevState,
      current: `${sharedProps.dateRange[0].format(
        'YYYY-MM-DD'
      )} - ${sharedProps.dateRange[1].format('YYYY-MM-DD')}`,
    }));

    setOpenCallsSpec(prevState => ({
      ...prevState,
      ...newProps,
    }));

    setClosedCallsSpec(prevState => ({
      ...prevState,
      ...newProps,
    }));
    setSelectedProperty(selectedProp);
    setCallLogsState(REQUEST_STATUS.PENDING);
  };

  const generateSingleUseNumber = async task => {
    setButtonTransition(true);
    setBtnTransId(task?.id);
    const maskedPayload = await maskPhoneNumber(
      authContext.user.contactNumber,
      task.residentPhoneNumber,
      task.id
    );

    maxPanelService.track('Mask Phone Number', {
      Page: 'Call Log',
      Property: task.propertyName,
      'Maintenance Name': task.maintenanceUserName,
      'Maintenance Issue': task.maintenanceIssue,
      'Call Entry Status': task.callEntryStatus,
    });
    if (maskedPayload.error !== true) {
      setButtonTransition(false);
      setBtnTransId(null);
      window.location.href = 'tel:' + maskedPayload?.callMaskNumber;
    } else {
      // console.error(maskedPayload?.message ?? 'Unknown error');
      setToasterError({
        show: true,
        message: 'Generating call masking number failed. Please contact support',
      });
      setButtonTransition(false);
      setBtnTransId(null);
    }
  };

  const openEditTaskModal = (e, callTaskList) => {
    const list = { ...editTaskDetails };
    list.unit = callTaskList?.unit;
    list.residentFirstName = callTaskList?.residentFirstName;
    list.maintenanceIssue = callTaskList?.maintenanceIssue;
    list.description = callTaskList?.description;
    list.isOpen = callTaskList?.isOpen;
    list.residentPhoneNumber = getDefaultFormat(callTaskList?.residentPhoneNumber);
    list.id = callTaskList?.id;
    setEditTaskDetails(list);
    setEditTaskModal(true);
  };

  const closeEditTaskModal = e => {
    e.preventDefault();
    setEditTaskModal(false);
  };

  const updateEditTask = async taskDetails => {
    const updateTaskRes = await updateCallTask(taskDetails?.id, taskDetails);
    maxPanelService.track('Update task', {
      ID: taskDetails.id,
    });
    if (updateTaskRes?.status === 400) {
      setToasterError({
        show: true,
        message: updateTaskRes?.message,
      });
    }

    await featchCallsPage(true, openCallsListSpec.currentPage, true);
    await featchCallsPage(false, closedCallsListSpec.currentPage, true);

    setEditTaskModal(false);
  };

  const getStatusLabel = status => {
    switch (status) {
      case 'close':
        return 'Closed';
      case 'archive':
        return 'Archived';
      case 'open':
      default:
        return 'Open';
    }
  };

  return (
    <>
      <Loader
        isLoading={
          isLoading ||
          openCallsState === REQUEST_STATUS.LOADING ||
          closedCallsState === REQUEST_STATUS.LOADING ||
          callLogsState === REQUEST_STATUS.LOADING
        }
      />
      <>
        <PropertySwitcher
          subTitle="call log"
          propLists={propertyLists}
          selectedProp={selectedProperty}
          onChange={handleSelectProperty}
        />
        <div className="container">
          <div className="row">
            <div className="col-lg-6 list-filters">
              <Dropdown
                  className="custom-filter-dropdown"
                  onSelect={onFilterStatus}
              >
                <Dropdown.Toggle variant="link" id="status">
                  {getStatusLabel(sharedListSpec.status)}
                </Dropdown.Toggle>
                <Dropdown.Menu className="full-width">
                  <Dropdown.Item eventKey="open">Open</Dropdown.Item>
                  <Dropdown.Item eventKey="archive">Archived</Dropdown.Item>
                  <Dropdown.Item eventKey="close">Closed</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
                <MultiSelect
                    className="multi-select-filter"
                    onChange={onFilterIssue}
                    options={issueTypes}
                    disableChip={true}
                    defaultValue={sharedListSpec.maintenanceIssue}
                    downArrow={IconAngleDown}
                    placeholder="Issue Type"
                />
              <div className="date-filter">
                <div
                  className="dropdown-toggle btn date-select"
                  onClick={() => {
                    dateFilterRef.current.openCalendar();
                  }}
                >
                  {getFilterDate()}
                </div>
                {dateRage.initial !== dateRage.current && (
                  <div className="reset-date" onClick={() => onFilterDateRange()}>
                    <Image src={IconClose} roundedCircle={true} />
                  </div>
                )}
                <DatePicker
                  ref={dateFilterRef}
                  value={sharedListSpec.dateRange}
                  range
                  sort
                  format={'YYYY-MM-DD'}
                  calendarPosition="bottom-left"
                  onChange={onFilterDateRange}
                />
              </div>
            </div>
            {selectedJobs[statusFilter==='open'||statusFilter==='archive' ? 'open' : 'close'].length !== 0 && (
              <div className="col-6 d-flex justify-content-end align-items-center">
                <div className="select-text me-3">{`${
                  selectedJobs[statusFilter==='open'||statusFilter==='archive' ? 'open' : 'close'].length
                } item(s) selected`}</div>
                <button className="btn btn-primary" onClick={handleMultiSelAction}>{`${
                    statusFilter==='open'||statusFilter==='archive' ? 'Close' : 'Re open'
                }  selected items`}</button>
              </div>
            )}
          </div>
          <div className="custom-tabs">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="emergency"
              onSelect={event => {
                setCallType(event);
                setSelectedJobs({ open: [], close: [] });
              }}
            >
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link className="cursor-pointer" eventKey="emergency">
                    Emergency{' '}
                    {openCallsListSpec.totalRecords > 0 && (
                      <span className="badge badge-pill badge-light ml-2">
                        {openCallsListSpec.totalRecords}
                      </span>
                    )}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="cursor-pointer" eventKey="maintenance">
                    Maintenance{' '}
                    {closedCallsListSpec.totalRecords > 0 && (
                      <span className="badge badge-pill badge-light ml-2">
                        {closedCallsListSpec.totalRecords}
                      </span>
                    )}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="emergency">
                  {deviceName === DEVICES.MOBILE || deviceName === DEVICES.TABLET ? (
                    <CallListMobile
                      callList={openCalls}
                      generateSingleUseNumber={generateSingleUseNumber}
                      btnTransId={btnTransId}
                      buttonTransition={buttonTransition}
                      callAction={callAction}
                      setLoading={setLoading}
                      isOpen={statusFilter === 'open' || statusFilter === 'archive' ? true : false}
                    />
                  ) : (
                    <CallListDesktop
                      callList={openCalls}
                      fetchCallsSort={fetchCallsSort}
                      getSortByClass={getSortByClass}
                      openEditTaskModal={openEditTaskModal}
                      callAction={callAction}
                      setLoading={setLoading}
                      isOpen={statusFilter === 'open' || statusFilter === 'archive' ? true : false}
                      selectedJobs={selectedJobs}
                      setSelectedJobs={setSelectedJobs}
                    />
                  )}
                  {openCallsListSpec.totalRecords > pageSize && (
                    <CallListPagination
                      ListSpec={openCallsListSpec}
                      featchCallsPage={featchCallsPage}
                      isOpen={callType}
                    />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="maintenance">
                  {deviceName === DEVICES.MOBILE || deviceName === DEVICES.TABLET ? (
                    <CallListMobile
                      callList={closedCalls}
                      generateSingleUseNumber={generateSingleUseNumber}
                      btnTransId={btnTransId}
                      buttonTransition={buttonTransition}
                      callAction={callAction}
                      setLoading={setLoading}
                      isOpen={statusFilter === 'open' || statusFilter === 'archive' ? true : false}
                    />
                  ) : (
                    <CallListDesktop
                      callList={closedCalls}
                      fetchCallsSort={fetchCallsSort}
                      getSortByClass={getSortByClass}
                      openEditTaskModal={openEditTaskModal}
                      callAction={callAction}
                      setLoading={setLoading}
                      isOpen={statusFilter === 'open' || statusFilter === 'archive' ? true : false}
                      selectedJobs={selectedJobs}
                      setSelectedJobs={setSelectedJobs}
                    />
                  )}
                  {closedCallsListSpec.totalRecords > pageSize && (
                    <CallListPagination
                      ListSpec={closedCallsListSpec}
                      featchCallsPage={featchCallsPage}
                      isOpen={callType}
                    />
                  )}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </>
      <EditTaskModal
        show={editTaskModal}
        onHide={() => setEditTaskModal(false)}
        details={editTaskDetails}
        closemodal={e => closeEditTaskModal(e)}
        handleupdate={val => updateEditTask(val)}
        setToasterError={setToasterError}
      />
      <ToastMessage
        show={toasterError.show}
        message={toasterError.message}
        type={'danger'}
        onClose={() => {
          setToasterError({ show: false, message: '' });
        }}
      />
    </>
  );
};

export default CallLog;
