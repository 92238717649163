import { useCallback, useEffect, useState } from 'react';
import { REQUEST_STATUS } from '../constants/loading';

export default function useFetchApiList({ fetchApi, transformListSpec, listSpecInitial }) {
  const [list, setList] = useState([]);
  const [listSpec, setListSpec] = useState(listSpecInitial);
  const [listState, setListState] = useState(REQUEST_STATUS.IDLE);

  const loadData = useCallback(async () => {
    try {
      setListState(REQUEST_STATUS.LOADING);
      const userData = await fetchApi(transformListSpec(listSpec));
      setList(userData.list);
      setListSpec(prevSpec => ({
        ...prevSpec,
        pageMax: Math.ceil(userData?.totalRecords / prevSpec.pageSize),
        totalRecords: userData?.totalRecords,
      }));
    } catch (err) {
      console.error(err);
    }
    setListState(REQUEST_STATUS.SUCCESS);
  }, [fetchApi, listSpec, transformListSpec]);

  const getListSortReq = sortColumn => {
    setListSpec(prevSpec => {
      const sortDirection =
        prevSpec.sortColumn === sortColumn && prevSpec.sortDirection === 'ASC' ? 'DESC' : 'ASC';

      return {
        ...prevSpec,
        currentPage: 1,
        sortColumn,
        sortDirection,
      };
    });
    setListState(REQUEST_STATUS.PENDING);
  };

  const getListPageReq = pageNo => {
    setListSpec(prevSpec => ({
      ...prevSpec,
      currentPage: pageNo,
      //   offset: (pageNo - 1) * prevSpec.pageSize,
    }));
    setListState(REQUEST_STATUS.PENDING);
  };

  const getListQueryReq = query => {
    setListSpec(prevSpec => ({
      ...prevSpec,
      searchText: query,
      //   offset: (pageNo - 1) * prevSpec.pageSize,
    }));
    setListState(REQUEST_STATUS.PENDING);
  };

  useEffect(() => {
    if (listState === REQUEST_STATUS.IDLE) {
      setListState(REQUEST_STATUS.PENDING);
    }
    if (listState === REQUEST_STATUS.PENDING) {
      loadData();
    }
  }, [listState, loadData]);

  return {
    list,
    listSpec,
    listState,
    setListState,
    loadData,
    getListSortReq,
    getListPageReq,
    getListQueryReq,
  };
}
