import { useState, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { logout } from '../../services/authServices';
import logo from '../../assets/images/logo.svg';
import logoMobile from '../../assets/images/logo-icon.svg';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import AuthContext from '../../store/AuthContext';
import { DEVICES } from '../../constants/devices';
import maxPanelService from '../../services/mixPanelService';

const Header = () => {
  const [, deviceName] = useWindowDimensions();
  const authContext = useContext(AuthContext);
  const [isSideNav, setIsSideNav] = useState(false);

  const openSideNav = () => {
    setIsSideNav(true);
  };

  const closeSideNav = () => {
    setIsSideNav(false);
  };

  const navEvent = (name, method = 'navbar') => {
    maxPanelService.track('Navigate', {
      Page: name,
      Method: method,
    });
  };

  return (
    <header className="header-nav fixed-top">
      <nav className="navbar">
        <div className="container">
          <div className="navbar-brand">
            <NavLink to="/call-log" className="logo" onClick={() => navEvent('Call Log', 'logo')}>
              {deviceName === DEVICES.MOBILE ? (
                <img src={logoMobile} alt="logo" />
              ) : (
                <img src={logo} alt="logo" />
              )}
            </NavLink>
          </div>
          <div className="navbar-links">
            {deviceName === DEVICES.MOBILE || deviceName === DEVICES.TABLET ? (
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <button className="nav-link" onClick={openSideNav}>
                    <i className="icon-hamburger text-dark"></i>
                  </button>
                </li>
              </ul>
            ) : (
              <ul className="nav nav-tabs">
                {authContext.isSysAdmin && (
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      to="/properties"
                      onClick={() => navEvent('Properties')}
                    >
                      Properties
                    </NavLink>
                  </li>
                )}
                <li className="nav-item">
                  <NavLink className="nav-link" to="/users" onClick={() => navEvent('Users')}>
                    Users
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/call-log" onClick={() => navEvent('Call Log')}>
                    Call Log
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/call-settings"
                    onClick={() => navEvent('Call Settings')}
                  >
                    Call Settings
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                      className="nav-link"
                      to="/property-stat-report"
                      onClick={() => navEvent('Report')}
                  >
                    Reports
                  </NavLink>
                </li>
                {authContext.isSysAdmin && (<li className="nav-item">
                  <NavLink
                      className="nav-link"
                      to="/analytics"
                      onClick={() => navEvent('Report')}
                  >
                    Analytics
                  </NavLink>
                </li>)}
                <li className="nav-item">
                  <NavLink
                    exact
                    className="nav-link"
                    to="/"
                    onClick={() => {
                      logout();
                      navEvent('Log Out');
                    }}
                  >
                    Log Out
                  </NavLink>
                </li>
              </ul>
            )}
          </div>
        </div>
      </nav>

      {(deviceName === DEVICES.MOBILE || deviceName === DEVICES.TABLET) && (
        <Modal
          className="custom-modal custom-drawer sidenav-menu"
          show={isSideNav}
          onHide={() => setIsSideNav(false)}
          centered
        >
          <Modal.Header>
            <img src={logoMobile} alt="logo" />
            <button className="btn p-1 font-size-sm border-0" onClick={closeSideNav}>
              <i className="icon-cross"></i>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div>
              <ul className="nav nav-tabs d-block">
                <li className="nav-item">
                  <NavLink className="nav-link" to="/call-log" onClick={() => navEvent('Call Log')}>
                    Call Log
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/call-settings"
                    onClick={() => navEvent('Call Settings')}
                  >
                    Call Settings
                  </NavLink>
                </li>
                {authContext.isSysAdmin && (
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      to="/properties"
                      onClick={() => navEvent('Properties')}
                    >
                      Properties
                    </NavLink>
                  </li>
                )}
                <li className="nav-item">
                  <NavLink className="nav-link" to="/users" onClick={() => navEvent('Users')}>
                    Users
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                      className="nav-link"
                      to="/property-stat-report"
                      onClick={() => navEvent('Report')}
                  >
                    Report
                  </NavLink>
                </li>
                {authContext.isSysAdmin && (<li className="nav-item">
                  <NavLink
                      className="nav-link"
                      to="/analytics"
                      onClick={() => navEvent('Report')}
                  >
                    Analytics
                  </NavLink>
                </li>)}
                <li className="nav-item">
                  <NavLink
                    exact
                    className="nav-link"
                    to="/"
                    onClick={() => {
                      logout();
                      navEvent('Log Out');
                    }}
                  >
                    Log Out
                  </NavLink>
                </li>
              </ul>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </header>
  );
};

export default Header;
