const config = {
  apiUrl: process.env.REACT_APP_API_URL,
  s3: {
    region: process.env.REACT_APP_S3_REGION,
    bucket: process.env.REACT_APP_S3_BUCKET,
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
    presignExpireTime: process.env.REACT_APP_S3_PRESIGN_EXPIRE_TIME,
  },
};

export default config;
