import Header from '../Header/Header';

const HeaderLayout = props => {
  return (
    <>
      <Header />
      {props.sub && (
        <div className="main-content">
          <props.sub />
        </div>
      )}
    </>
  );
};

export default HeaderLayout;
