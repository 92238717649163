import mixpanel from 'mixpanel-browser';

// TODO: check if production
const env_check = process.env.REACT_APP_MP_ENABLE;

if (env_check) {
  mixpanel.init(process.env.REACT_APP_MP_TOKEN, {
    debug: process.env.REACT_APP_MP_DEBUG,
    ignore_dnt: true,
  });
}

const mixPanelService = {
  identify: id => {
    if (env_check) mixpanel.identify(id);
  },
  alias: id => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (id, props) => {
      if (env_check) mixpanel.people.set(id, props);
    },
  },
};

export default mixPanelService;
