import React from 'react';
import logoWithText from './../../assets/images/logo.svg';

export default function AppLoading({ fadeAway = true }) {
  return (
    <div className={fadeAway ? 'getting-started fade-out' : 'getting-started'}>
      <div className="d-flex justify-content-center align-items-center min-vh-100">
        <img src={logoWithText} className="" alt="logo" />
      </div>
    </div>
  );
}
