import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import Accordion from 'react-bootstrap/Accordion';
import {
  fetchPropertyList,
  fetchPropertyUsers,
  fetchWeeklySettings,
  updateCourtesyOfficer,
  updateWeeklySettings,
} from '../../services/dashBoardServices';
import { getSelectedProperty, updateSelectedProperty } from '../../services/localServices';
import maxPanelService from '../../services/mixPanelService';
import AuthContext from '../../store/AuthContext';
import Loader from '../../components/Loader/Loader';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import PropertySwitcher from '../../components/PropertySwitcher/PropertySwitcher';
import ToastMessage from '../../components/ToastMessage/ToastMessage';
import UserSelect from '../../components/CallSettings/UserSelect';
import { DEVICES } from '../../constants/devices';
import { REQUEST_STATUS } from '../../constants/loading';
import {
  getSettingsDateValue,
  getTzStartDate,
  getWeekDates,
  getWeekNames,
} from '../../helpers/date';
import MultiSelect from "react-multiple-select-dropdown-lite";

const formFields = [
  {
    name: 'Business Hours Contact',
    userKey: 'businessHoursUserId',
    contactKey: 'businessHoursContact',
  },
  {
    name: 'After Hours Contact',
    userKey: 'offHoursUserId',
    contactKey: 'offHoursContact',
  },
  {
    name: 'Alternate 1',
    userKey: 'alternateOneUserId',
    contactKey: 'alternateOneContact',
  },
  {
    name: 'Alternate 2',
    userKey: 'alternateTwoUserId',
    contactKey: 'alternateTwoContact',
  },
];

export const optionalKeys = ['alternateTwoUserId'];

const WeeklySettings = () => {
  const authContext = useContext(AuthContext);
  const [selectedProperty, setSelectedProperty] = useState({});
  const [selectedWeek, setSelectedWeek] = useState(1);
  const [weekNames, setWeekNames] = useState([
    { label: 'Loading...', value: 1 },
    { label: 'Loading...', value: 2 },
    { label: 'Loading...', value: 3 },
    { label: 'Loading...', value: 4 },
  ]);
  const [propertyLists, setPropertyLists] = useState([]);
  const [propertyListsState, setPropertyListsState] = useState(REQUEST_STATUS.IDLE);
  const [propertyUsers, setPropertyUsers] = useState([]);
  const [courtesyOfficerListOption, setCourtesyOfficerListOption] = useState([]);
  const [propertyUsersState, setPropertyUsersState] = useState(REQUEST_STATUS.IDLE);
  const [properties, setProperties] = useState([]);
  const [propertiesState, setPropertiesState] = useState(REQUEST_STATUS.IDLE);
  const [propertiesForm, setPropertiesForm] = useState({});
  const [toastMessage, setToastMessage] = useState({ show: false, message: '' });
  const [, deviceName] = useWindowDimensions();
  const [updateState, setUpdateState] = useState({
    messageIndex: null,
    showMessage: false,
    success: false,
  });
  const [submitForm, setSubmitForm] = useState({
    rowIndex: null,
    validated: false,
    submit: false,
  });

  useEffect(() => {
    // Scroll to top when page loads
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    //get property
    if (propertyListsState === REQUEST_STATUS.IDLE) {
      setPropertyListsState(REQUEST_STATUS.PENDING);
    }

    //get property list
    const loadAsync = async () => {
      setPropertyListsState(REQUEST_STATUS.LOADING);
      const user = authContext.user;
      const propList = await fetchPropertyList(user?.id);
      setPropertyLists(propList.propertyList);

      // Set selected Property
      let selectedProp = getSelectedProperty();
      if (!selectedProp?.currentDateTime) {
        selectedProp = propList.propertyList[0];
      }

      // Get selected propery with current datetime
      selectedProp = propList.propertyList.find(property => {
        return property.propertyId === selectedProp.propertyId;
      });

      updateSelectedProperty(selectedProp);
      setSelectedProperty(selectedProp);

      setPropertyListsState(REQUEST_STATUS.SUCCESS);
      setPropertyUsersState(REQUEST_STATUS.PENDING);
    };
    if (propertyListsState === REQUEST_STATUS.PENDING) {
      loadAsync();
    }
  }, [authContext.user, propertyListsState]);

  const getPropertyUsers = useCallback(async () => {
    setPropertyUsersState(REQUEST_STATUS.LOADING);
    setCourtesyOfficerListOption([]);
    const propUsers = await fetchPropertyUsers(selectedProperty.propertyId);
    setPropertyUsers(propUsers?.userList);
    const listOptions = propUsers?.userList.map(item => ({
      label: item.firstName + ' ' + item.lastName,
      value: item.id,
    }));
    setCourtesyOfficerListOption(listOptions);
    setPropertyUsersState(REQUEST_STATUS.SUCCESS);
    setPropertiesState(REQUEST_STATUS.PENDING);
  }, [selectedProperty.propertyId]);

  const getUserId = useCallback(
    userId => {
      return propertyUsers.find(user => user.id === userId) ? userId : null;
    },
    [propertyUsers]
  );

  const transformProperties = useCallback(
    property => {
      const form = [];
      let weekdayContactData = property.maintenanceRollingContactList;
      const tzDatetime = new Date(selectedProperty.currentDateTime);
      const tzStartDate = getTzStartDate(tzDatetime);

      setWeekNames(getWeekNames(tzStartDate));

      property.maintenanceRollingContactList.map((weekdayContact, index) => {
        form[index] = {
          validated: false,
          businessHoursUserId: {
            value: getUserId(weekdayContact.businessHoursUserId),
            required: false,
            format: false,
          },
          offHoursUserId: {
            value: getUserId(weekdayContact.offHoursUserId),
            required: false,
            format: false,
          },
          alternateOneUserId: {
            value: getUserId(weekdayContact.alternateOneUserId),
            required: false,
            format: false,
          },
          alternateTwoUserId: {
            value: getUserId(weekdayContact.alternateTwoUserId),
            required: false,
            format: false,
          },
        };

        let datetime = getWeekDates(
          tzStartDate,
          weekdayContactData[index].week,
          weekdayContactData[index].order
        );
        weekdayContactData[index].datetime = getSettingsDateValue(datetime);
        setPropertiesForm(form);
      });
      return weekdayContactData;
    },
    [getUserId, selectedProperty.currentDateTime]
  );

  const handleFormSubmit = useCallback(
    async index => {
      // Prevent initial execution
      if (propertiesForm && !propertiesForm[index]) {
        return;
      }

      let error = false;
      let diff = false;
      let propertyItem = {};

      // validate all keys and asign value to propertyItem
      formFields.map(formField => {
        if (
          propertiesForm[index][formField.userKey].required ||
          propertiesForm[index][formField.userKey].format
        ) {
          error = true;
        }
        if (
          propertiesForm[index][formField.userKey].value !== properties[index][formField.userKey]
        ) {
          diff = true;
        }
        propertyItem[formField.userKey] = propertiesForm[index][formField.userKey].value;
      });

      // if no error or difference in form values
      if (error || !diff) {
        return;
      }

      // update property payload
      const updateProp = {
        propertyId: properties[index].propertyId,
        maintenanceRollingContactList: [
          {
            ...properties[index],
            ...propertyItem,
          },
        ],
      };

      maxPanelService.track('Update Settings', {
        Page: 'Call Settings',
        'Property ID': properties[index].propertyId,
        Week: properties[index].week,
        Order: properties[index].order,
      });

      const propertiesRes = await updateWeeklySettings(updateProp);

      const success = propertiesRes?.maintenanceRollingContactList?.length;

      setUpdateState({
        messageIndex: index,
        showMessage: true,
        success: !!success,
      });

      if (success) {
        // Update new data to properties object so form data can be reset
        setPropertiesState(REQUEST_STATUS.PENDING);
      } else {
        setToastMessage({
          show: true,
          message: propertiesRes?.message ?? 'Something went wrong! Please try again later',
        });
        // Reset form data to last fetched properties data
        transformProperties({
          maintenanceRollingContactList: JSON.parse(JSON.stringify(properties)),
        });
      }
      return success;
    },
    [properties, propertiesForm, transformProperties]
  );

  const getProperties = useCallback(async () => {
    const prop = await fetchWeeklySettings(selectedProperty?.propertyId);
    let propData = [];
    if (!prop.error) {
      propData = transformProperties(prop.data);
    } else {
      setToastMessage({
        show: true,
        message: prop.message,
      });
    }
    return propData;
  }, [selectedProperty?.propertyId, transformProperties]);

  useEffect(() => {
    if (propertyUsersState === REQUEST_STATUS.PENDING) {
      // set selected Property
      getPropertyUsers();
    }
  }, [getPropertyUsers, propertyUsersState]);

  useEffect(() => {
    const asyncLoad = async () => {
      setPropertiesState(REQUEST_STATUS.LOADING);
      const res = await getProperties();
      setProperties(res);
      setPropertiesState(REQUEST_STATUS.SUCCESS);
    };
    if (propertiesState === REQUEST_STATUS.PENDING) {
      asyncLoad();
    }
  }, [getProperties, propertiesState]);

  useEffect(() => {
    const asyncLoad = async () => {
      await handleFormSubmit(submitForm.rowIndex);
      setSubmitForm({
        rowIndex: null,
        validated: false,
        submit: false,
      });
    };
    if (submitForm.validated) {
      asyncLoad();
    }
  }, [handleFormSubmit, submitForm]);

  const handleSelectUser = (index, contactType, userId) => {
    const form = { ...propertiesForm };
    formFields.map(formField => {
      form[index][formField.userKey] = {
        ...hasError(form[index][formField.userKey].value, formField.userKey),
        value: form[index][formField.userKey].value,
      };
    });
    form[index][contactType] = {
      ...hasError(userId, contactType),
      value: userId,
    };

    setSubmitForm(prevState => ({
      ...prevState,
      rowIndex: index,
      validated: true,
      submit: false,
    }));

    setPropertiesForm(form);
  };

  const handleCourtesyOfficer = async val => {
    let selectedCourtesyOfficer = courtesyOfficerListOption.find(item => {
      return item.value === val;
    });

    if (!selectedCourtesyOfficer) {
      selectedCourtesyOfficer = {
        propertyId: selectedProperty.propertyId,
        courtesyOfficerUserId: ''
      };
    }else{
        selectedCourtesyOfficer = {
            propertyId: selectedProperty.propertyId,
            courtesyOfficerUserId: selectedCourtesyOfficer.value
        };
    }
    const res = await updateCourtesyOfficer(selectedProperty?.propertyId, selectedCourtesyOfficer);
    if(res.error){
        setToastMessage({
            type: 'danger',
            show: true,
            message: res.message,
        });
    }else {
      let selectedProp = getSelectedProperty();
      selectedProp.courtesyOfficerUserId = res.courtesyOfficerUserId;
      updateSelectedProperty(selectedProp);
      setSelectedProperty(selectedProp);
      const index = propertyLists.findIndex(function(obj) {
        return obj.propertyId === selectedProp.propertyId;
      });

      if (index !== -1) {
        propertyLists.splice(index, 1, selectedProp);
      }

      setToastMessage({
        type: 'success',
        show: true,
        message: 'Saved !',
      });
    }
  };

  const hasError = (value, contractType) => {
    const err = {
      required: false,
      format: false,
    };
    if ((!value || value.length === 0) && !optionalKeys.includes(contractType)) {
      err.required = true;
    } else if (
      !propertyUsers.find(user => user.id === value) &&
      !optionalKeys.includes(contractType)
    ) {
      err.format = true;
    }
    return err;
  };

  const handleSelectWeek = week => {
    maxPanelService.track('Select Week', {
      Page: 'Call Settings',
      Week: week,
    });

    setSelectedWeek(week);
  };

  const handleSelectProperty = selectedProp => {
    updateSelectedProperty(selectedProp);
    setSelectedProperty(selectedProp);

    maxPanelService.track('Select Property', {
      Page: 'Call Settings',
      'Property ID': selectedProp.propertyID,
      'Property Name': selectedProp.propertyName,
      'Time Zone': selectedProp.timeZone,
    });
    setPropertyUsersState(REQUEST_STATUS.PENDING);
  };

  return (
    <>
      <Loader
        isLoading={
          propertyListsState === REQUEST_STATUS.LOADING ||
          propertiesState === REQUEST_STATUS.LOADING
        }
      />
      <PropertySwitcher
        subTitle="call settings"
        propLists={propertyLists}
        selectedProp={selectedProperty}
        onChange={handleSelectProperty}
      />

      <div className="header mb-3">
        <div className="container">
          <div className="header-body">
            <div className="row align-items-end">
              <div className="col">
                {courtesyOfficerListOption.length > 0 && (
                    <div>
                        <label htmlFor="courtesyOfficerId" className="form-label">
                          Courtesy Officer
                        </label>
                        <MultiSelect
                            className="custom-multiple-select w-25"
                            onChange={handleCourtesyOfficer}
                            placeholder="No Courtesy Officer"
                            options={courtesyOfficerListOption}
                            defaultValue={selectedProperty?.courtesyOfficerUserId}
                            singleSelect
                            closeOnSelect
                        />
                    </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header mb-3">
        <div className="container">
          <div className="header-body">
            <div className="row align-items-end">
              <div className="col">
                <Dropdown className="custom-dropdown no-arrow">
                  <Dropdown.Toggle
                    variant="link"
                    id="dropdown-basic"
                    className="property-switcher d-flex align-items-center"
                  >
                    <h3 className="font-size-15 mb-0 text-truncate">
                      {weekNames[selectedWeek - 1].label || 'Property Not Set'}
                    </h3>
                    <i className="icon-chevron-down ms-3"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {weekNames.map((item, index) => {
                      return (
                        <Dropdown.Item
                          onClick={() => handleSelectWeek(item.value)}
                          value={item.value}
                          key={index}
                          as="button"
                        >
                          {item.label}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        {deviceName === DEVICES.MOBILE || deviceName === DEVICES.TABLET ? (
          <>
            {properties.map(
              (maintenanceContact, index) =>
                maintenanceContact.week === selectedWeek && (
                  <Accordion className="custom-accordion custom-accordion-cs">
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <div className="cs-header d-flex flex-column">
                          <h4 className="cs-day">
                            {maintenanceContact.datetime}
                            {updateState.messageIndex === index && updateState.showMessage && (
                              <ToastContainer className="position-relative">
                                <Toast
                                  className="custom-toast"
                                  onClose={() =>
                                    setUpdateState({ ...updateState, showMessage: false })
                                  }
                                  show={updateState.showMessage}
                                  delay={2000}
                                  autohide
                                >
                                  <Toast.Body>
                                    <p className="mb-0 font-size-sm line-height36 text-center text-secondary">
                                      {updateState.success ? 'Saved!' : 'Error!'}
                                    </p>
                                  </Toast.Body>
                                </Toast>
                              </ToastContainer>
                            )}
                          </h4>
                          <div className="cs-hours d-flex">
                            <p className="title mb-0">Business Hours</p>
                            <p className="time mb-0">
                              {`${maintenanceContact.businessHoursFrom} - ${maintenanceContact.businessHoursTo}`}
                            </p>
                          </div>
                        </div>
                        <i className="toggle-arrow icon-chevron-right"></i>
                      </Accordion.Header>
                      <Accordion.Body>
                        {formFields.map(formField => (
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <p className="mb-0">{formField.name}</p>
                            <UserSelect
                              propertyUsers={propertyUsers}
                              handleSelectUser={handleSelectUser}
                              userId={propertiesForm[index][formField.userKey].value}
                              // contactNo={maintenanceContact[formField.contactKey]}
                              contactType={formField.userKey}
                              rowId={index}
                              showError={propertiesForm[index][formField.userKey].required}
                            />
                          </div>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )
            )}
          </>
        ) : (
          <div className="card">
            <div className="table-responsive mb-0">
              <table className="table table-sm table-mob table-nowrap card-table head-light">
                <thead>
                  <tr>
                    <th>Day</th>
                    <th>Business Hours</th>
                    <th>Business Hours Contact</th>
                    <th>After Hours Contact</th>
                    <th>Alternate 1</th>
                    <th>Alternate 2</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {properties.map((maintenanceContact, index) => {
                    let animation = '';
                    if (updateState.messageIndex === index && updateState.showMessage) {
                      animation = updateState.success
                        ? 'border-blink--success 2.2s ease'
                        : 'border-blink--danger 2.2s ease';
                    } else {
                      animation = '';
                    }
                    return (
                      maintenanceContact.week === selectedWeek && (
                        <tr
                          key={index}
                          style={{
                            animation: animation,
                          }}
                        >
                          <td className="align-top" data-label="Day">
                            <p className="mb-0 line-height36">{maintenanceContact.datetime}</p>
                          </td>
                          <td className="align-top" data-label="Business Hours">
                            <p className="mb-0 line-height36">
                              {maintenanceContact.isOpen && (
                                <span>
                                  {`${maintenanceContact.businessHoursFrom} - ${maintenanceContact.businessHoursTo}`}
                                </span>
                              )}
                              {!maintenanceContact.isOpen && <span>Closed</span>}
                            </p>
                          </td>
                          {formFields.map((formField, id) => (
                            <td key={id} className="align-top" data-label="Business Hours Contact">
                              <UserSelect
                                propertyUsers={propertyUsers}
                                handleSelectUser={handleSelectUser}
                                userId={propertiesForm[index][formField.userKey].value}
                                contactNo={maintenanceContact[formField.contactKey]}
                                contactType={formField.userKey}
                                rowId={index}
                              />
                              {propertiesForm[index][formField.userKey].required && (
                                <p className="text-danger mb-0 mt-2">Contact required</p>
                              )}
                            </td>
                          ))}
                          <td className="align-top no-label">
                            <div className="save-message">
                              {updateState.messageIndex === index && updateState.showMessage && (
                                <ToastContainer className="position-relative">
                                  <Toast
                                    className="custom-toast"
                                    onClose={() =>
                                      setUpdateState({ ...updateState, showMessage: false })
                                    }
                                    show={updateState.showMessage}
                                    delay={3000}
                                    autohide
                                  >
                                    <Toast.Body>
                                      <p className="mb-0 font-size-sm line-height36 text-center text-secondary">
                                        {updateState.success ? 'Saved!' : 'Error!'}
                                      </p>
                                    </Toast.Body>
                                  </Toast>
                                </ToastContainer>
                              )}
                            </div>
                          </td>
                        </tr>
                      )
                    );
                  })}
                  {!properties?.length && (
                    <>
                      <tr>
                        <td colSpan="6" data-label="DAY">
                          <p className="mb-0 py-4 text-center">Nothing Here</p>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      <ToastMessage
        type={toastMessage.type || "success"}
        show={toastMessage.show}
        message={toastMessage.message}
        onClose={() => setToastMessage({ show: false, message: '' })}
      />
    </>
  );
};

export default WeeklySettings;
