import Logo from '../../assets/images/logo.svg'
import {Link, useHistory} from "react-router-dom";
import {useEffect} from "react";
import {Account_Not_Found_Key} from "../../services/authServices";

const UnAuthorize = () => {
    const history = useHistory()
    useEffect(() => {
        if(!sessionStorage.getItem(Account_Not_Found_Key)) history.push('/');
    },[history])

    return (
        <div className='d-flex align-items-center justify-content-center vh-100'>
            <div className='login-wrapper'>
                <div className="container">
                    <div className='logo-wrap text-center'>
                        <img src={Logo} alt="logo"/>
                    </div>
                    <div className='login-footer d-flex flex-column justify-content-between'>
                        <div>
                            <h1 className='heading1 login-heading text-center mb-3'>Account Not Found</h1>
                            <p className=''>We are unable to find your JobCall account. Please contact <a
                                href="mailto:support@jobcall.com">support@jobcall.com</a> for assistance.</p>
                        </div>
                        <Link to='/'>
                            <button type='button' className='btn btn-primary btn-block btn-xl'>
                                Return to Log In
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UnAuthorize;