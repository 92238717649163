import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import config from '../config/app';

export const getS3Object = object_url => {
  // Initialize the Amazon Cognito credentials provider

  const s3 = new S3Client({
    region: config.s3.region,
    credentials: {
      accessKeyId: config.s3.accessKeyId,
      secretAccessKey: config.s3.secretAccessKey,
    },
  });

  const { objectKey, bucketPrefix } = getObjectKeyFromURL(object_url);

  return new Promise(async (resolve, reject) => {
    try {
      const getObjectCommand = new GetObjectCommand({
        Bucket: bucketPrefix,
        Key: objectKey,
      });
      const url = await getSignedUrl(s3, getObjectCommand, {
        expires: config.s3.presignExpireTime,
      });
      resolve(url);
    } catch (err) {
      // Handle the error or throw
      return reject(err);
    }
  });
};

const getObjectKeyFromURL = object_url => {
  const parts = object_url.split('.s3.us-west-2.amazonaws.com/');
  const objectKey = parts[parts.length - 1];
  const bucketPrefix = parts[0].slice('https://'.length, parts[0].length);

  return { objectKey, bucketPrefix };
};
