import { useEffect, useMemo, useState } from 'react';
import { getS3Object } from '../helpers/aws';

/**
 *
 * @param {string} url
 * @returns {[isPlaying: boolean, togglePlay: ()=>{}, currentTime: number, duration: number]}
 */
const useAudio = url => {
  // create audio only once
  const [audioUrl, setAudioUrl] = useState(null);
  const [audio, setAudio] = useState(new Audio());
  const [isLoading, setIsLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [duration, setDuration] = useState(0);

  useMemo(() => {
    setIsReady(false);
    setAudioUrl(url);
  }, [url]);

  useEffect(() => {
    const resetAudio = () => {
      setPlay(false);
      if (audio?.duration) audio.pause();
    };

    audio?.addEventListener('ended', resetAudio);
    return () => {
      resetAudio();
      audio?.removeEventListener('ended', resetAudio);
    };
  }, [audio, audioUrl]);

  const [play, setPlay] = useState(false);

  const toggle = () => setPlay(!play);

  useEffect(() => {
    if (isReady) {
      setPlay(true);
      audio
        .play()
        .then(() => {
          setIsLoading(false);
          setDuration(audio.duration);
        })
        .catch(err => {
          setIsLoading(false);
          setPlay(false);
        });
    }
  }, [isReady]);

  useEffect(() => {
    play ? playingSong() : pausingSong();
  }, [play]);

  const playingSong = () => {
    if (!isReady && !isLoading) {
      setIsLoading(true);
      getS3Object(audioUrl).then(presignedUrl => {
        const audio = new Audio(presignedUrl);
        setAudio(audio);
        setIsReady(true);
      });
    }
    if (isReady) {
      if (audio?.duration) {
        audio.play();
      } else if (!isLoading) {
        setTimeout(() => {
          setPlay(false);
        }, 1000);
      }
    }
  };
  const pausingSong = () => {
    if (audio?.duration) {
      audio.pause();
      audio.currentTime = 0;
    }
  };

  return [play, toggle, duration];
};

export default useAudio;
