import React from 'react';
import { Modal } from 'react-bootstrap';

const DeleteModal = ({ showModal, closeModal, titleName, entityName, entityId, onDelete }) => {
  return (
    <>
      <Modal
        className="custom-modal no-border delete-modal"
        show={showModal}
        onHide={closeModal}
        centered
      >
        <Modal.Body>
          <div className="d-flex align-items-center mb-3">
            <i className="icon-caution font-size-xl text-danger me-2"></i>
            <h3 className="font-size-28 mb-0">Delete {titleName}</h3>
          </div>
          <p className="mb-0">
            <span className="d-block mb-2">Are you sure you want to delete {entityName}? </span>
            <div className="action-undone"> This action cannot be undone.</div>
          </p>
        </Modal.Body>
        <Modal.Footer className="pt-0 pb-4">
          <button
            className="btn me-0 btn-outline-light me-lg-4 text-dark"
            onClick={() => closeModal()}
          >
            Cancel
          </button>
          <button className="btn btn-danger" onClick={() => onDelete(entityId)}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteModal;
