import React from 'react';
import { Pagination } from 'react-bootstrap';

export default function CallListPagination({ ListSpec, featchCallsPage, isOpen }) {
  return (
    <div className="pagination-wrap">
      <div className="page-number">{`Page ${ListSpec.currentPage} of ${ListSpec.maxPage}`}</div>
      <Pagination>
        <Pagination.First
          onClick={() => featchCallsPage(isOpen, 1)}
          disabled={ListSpec.currentPage === 1}
        />
        <Pagination.Prev
          onClick={() => featchCallsPage(isOpen, ListSpec.currentPage - 1)}
          disabled={ListSpec.currentPage === 1}
        />
        <Pagination.Next
          onClick={() => featchCallsPage(isOpen, ListSpec.currentPage + 1)}
          disabled={ListSpec.currentPage === ListSpec.maxPage}
        />
        <Pagination.Last
          onClick={() => featchCallsPage(isOpen, ListSpec.maxPage)}
          disabled={ListSpec.currentPage === ListSpec.maxPage}
        />
      </Pagination>
    </div>
  );
}
