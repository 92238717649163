import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import IssueBadge from '../IssueBadge/IssueBadge';
import { getDateAtTime } from '../../helpers/date';
import EmptyList from './EmptyList';

export default function CallListMobile({
  callList,
  generateSingleUseNumber,
  btnTransId,
  buttonTransition,
  callAction,
  setLoading,
  isOpen,
}) {
  return (
    <div>
      {callList?.list?.map((openCall, index) => {
        return (
          <div key={openCall.id} className="card mb-2">
            <div className="card-body p-4 call-log-list">
              <div className="d-flex justify-content-between align-content-start">
                <div className="d-flex align-items-end mb-3">
                  <h2 className="font-weight-bold mb-0">{openCall?.unit}</h2>
                  {!openCall?.isValidUnit && (
                    <OverlayTrigger
                      placement="top"
                      delay={{
                        show: 250,
                        hide: 400,
                      }}
                      overlay={
                        <Tooltip>
                          This unit number is invalid. Please edit it to create a work order in
                          ResMan.
                        </Tooltip>
                      }
                    >
                      <h3 className="px-1 py-0 line-height-1 unit-error-icon mb-0 cursor-pointer">
                        <i className="icon-caution text-danger"></i>
                      </h3>
                    </OverlayTrigger>
                  )}
                </div>

                <h4 className="font-weight-normal">
                  <IssueBadge type={openCall?.maintenanceIssue} />
                </h4>
              </div>
              <div className="d-flex justify-content-between align-items-end mb-4">
                <div className="pb-2">
                  <p className="sub-title">Call Date/Time</p>
                  <h5 className="font-weight-normal">{getDateAtTime(openCall?.callTime)}</h5>
                </div>
                <Link to={`call-log/${openCall?.id}`} className="btn py-2 px-1 font-size-xs">
                  <i className="icon-chevron-right"></i>
                </Link>
              </div>
              <button
                onClick={() => generateSingleUseNumber(openCall)}
                className={
                  btnTransId === openCall?.id && buttonTransition
                    ? 'btn btn-success btn-block py-2 mb-3'
                    : 'btn btn-primary btn-block py-2 mb-3'
                }
              >
                {btnTransId === openCall?.id && buttonTransition
                  ? 'Generating single use phone number...'
                  : 'Call resident'}
              </button>
              <button
                type="button"
                onClick={() =>
                  callAction(openCall.id, { updateAction: isOpen ? 'close' : 'open' }).then(() => {
                    setLoading(false);
                  })
                }
                className="btn btn-outline-primary no-focus btn-block py-2"
              >
                {isOpen ? 'Mark as closed' : 'Re-open'}
              </button>
            </div>
          </div>
        );
      })}
      {!callList?.list?.length && (
        <EmptyList
          caption={`There are no ${
            isOpen ? 'open' : 'closed'
          } calls at this property. Try adjusting the filters to view call entries.`}
        />
      )}
    </div>
  );
}
