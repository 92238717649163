import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Dropdown } from 'react-bootstrap';
import { fetchResmanUnits } from '../../services/dashBoardServices';
import { getSelectedProperty } from '../../services/localServices';
import { toTitleCase } from '../../helpers/helpers';

const EditTaskModal = ({ show, onHide, details, closemodal, handleupdate, setToasterError }) => {
  const [taskDetails, setTaskDetails] = useState(details);
  const [unitValue, setUnitValue] = useState(details?.unit);
  const [smartUnitList, setSmartUnitList] = useState();
  const [listLoading, setListLoading] = useState(false);
  const [dropdownShow, setDropdownShow] = useState(false);
  const [showModal, setShowModal] = useState(show);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  let detailsToSend = {
    propertyId: '',
    unitNumber: '',
  };

  useEffect(() => {
    setShowModal(show);
    setShowConfirmModal(false);
  }, [show]);

  useEffect(() => {
    setUnitValue(details.unit);
    setTaskDetails(details);
  }, [details]);

  const handleChangeUnit = async e => {
    setListLoading(true);
    const remainEdit = { ...details };
    const selectedProp = getSelectedProperty();
    setUnitValue(e.target.value);
    if (e.target.value.length > 1) {
      if (remainEdit?.isOpen) {
        detailsToSend = {
          propertyId: selectedProp?.propertyId,
          unitNumber: e.target.value,
        };
      } else {
        detailsToSend = {
          propertyId: selectedProp?.propertyId,
          unitNumber: e.target.value,
        };
      }
      const res = await fetchResmanUnits(detailsToSend);
      if (res?.status === 400) {
        const error = res.message ? res.message : 'Ops! Something went wrong. Try again later';
        setToasterError({ show: true, message: error });
      }
      setSmartUnitList(res);
      setListLoading(false);
    }
  };

  const updateUnitNumber = value => {
    setUnitValue(value);
    setTaskDetails(prevState => ({
      ...prevState,
      unit: value,
    }));
    setDropdownShow(false);
  };

  const dropdownOpen = () => {
    setDropdownShow(true);
  };

  const dropdownClose = () => {
    setDropdownShow(false);
    setUnitValue(taskDetails.unit);
  };

  const updateTaskDetails = (val, field) => {
    setTaskDetails(prevState => ({
      ...prevState,
      [field]: val,
    }));
  };

  const handleSubmit = async () => {
    handleupdate(taskDetails);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowConfirmModal(false);
    onHide();
  };

  const handleConfirm = e => {
    e.preventDefault();
    setShowModal(false);
    setShowConfirmModal(true);
  };

  const onHideConfirm = (showEdit = false) => {
    setShowConfirmModal(false);
    showEdit ? setShowModal(showEdit) : onHide();
  };

  return (
    <>
      <Modal show={showConfirmModal} onHide={onHideConfirm} centered>
        <Modal.Header>
          <h3 className="font-weight-bold mb-0">Confirm Details</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6 col-12">
              <p>
                <strong>Unit:</strong> <br />
                {taskDetails.unit ? taskDetails.unit : '-'}
              </p>
            </div>
            <div className="col-md-6 col-12">
              <p>
                <strong>Name:</strong> <br />
                {taskDetails.residentFirstName ? taskDetails.residentFirstName : '-'}
              </p>
            </div>
            <div className="col-md-6 col-12">
              <p>
                <strong>Phone Number:</strong> <br />
                {taskDetails.residentPhoneNumber}
              </p>
            </div>
            <div className="col-md-6 col-12">
              <p>
                <strong>Maintenance Issue:</strong> <br />
                {taskDetails.maintenanceIssue ? toTitleCase(taskDetails.maintenanceIssue) : '-'}
              </p>
            </div>
            <div className="col-12">
              <p>
                <strong>Description:</strong> <br />
                {taskDetails.description ? taskDetails.description : '-'}
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <button
              type="button"
              className="btn btn-outline-dark px-5 me-3"
              onClick={e => onHideConfirm(true)}
            >
              Edit
            </button>
            <button
              className="btn btn-primary px-5"
              type="submit"
              autoFocus={true}
              onClick={handleSubmit}
            >
              Confirm
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header>
          <h3 className="font-weight-bold mb-0">Edit Task</h3>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="mb-4 position-relative">
                  <label htmlFor="unitNo" className="form-label">
                    Unit Number <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="unitNo"
                    name="unit"
                    list="data"
                    autoComplete="off"
                    value={unitValue}
                    onFocus={dropdownOpen}
                    onBlur={dropdownClose}
                    onChange={e => handleChangeUnit(e)}
                  />
                  {unitValue?.length > 1 && dropdownShow && (
                    <div className="card custom-list-dropdown">
                      <ul className="card-body p-0 list-group list-group-flush unit-list-group">
                        {smartUnitList?.smartFuseUnitList?.length === 0 && !listLoading && (
                          <li className="list-group-item">Not found</li>
                        )}
                        {listLoading ? (
                          <li className="list-group-item">Loading</li>
                        ) : (
                          smartUnitList?.smartFuseUnitList?.map((option, index) => {
                            return (
                              <li
                                key={index}
                                onMouseDown={() => updateUnitNumber(option.unitNumber)}
                                className="list-group-item"
                              >
                                {option.unitNumber}
                              </li>
                            );
                          })
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="mb-4">
                  <label htmlFor="residentFName" className="form-label">
                    Resident Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="residentFName"
                    name="residentFirstName"
                    value={taskDetails.residentFirstName}
                    onChange={e => updateTaskDetails(e.target.value, 'residentFirstName')}
                  />
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="mb-4">
                  <label htmlFor="residentPhoneNo" className="form-label">
                    Resident Phone Number
                  </label>
                  <input
                    disabled
                    type="text"
                    className="form-control opacity-50"
                    id="residentPhoneNo"
                    name="residentPhoneNumber"
                    value={taskDetails.residentPhoneNumber}
                  />
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="mb-4">
                  <label htmlFor="role" className="form-label">
                    Maintenance Issue <span className="text-danger">*</span>
                  </label>
                  <Dropdown
                    className="custom-dropdown"
                    onSelect={val => updateTaskDetails(val, 'maintenanceIssue')}
                  >
                    <Dropdown.Toggle
                      className="outline-toggle text-dark text-capitalize"
                      variant="link"
                      id="role"
                    >
                      {taskDetails.maintenanceIssue}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="full-width">
                      <Dropdown.Item eventKey="heating">Heating</Dropdown.Item>
                      <Dropdown.Item eventKey="plumbing">Plumbing</Dropdown.Item>
                      <Dropdown.Item eventKey="electrical">Electrical</Dropdown.Item>
                      <Dropdown.Item eventKey="cooling">Cooling</Dropdown.Item>
                      <Dropdown.Item eventKey="other">Other</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="col-12">
                <div className="mb-5">
                  <label htmlFor="taskDescription" className="form-label">
                    Description <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="taskDescription"
                    name="description"
                    aria-label="update"
                    value={taskDetails?.description}
                    onChange={e => updateTaskDetails(e.target.value, 'description')}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end align-items-center">
                <button
                  type="button"
                  className="btn btn-outline-dark px-5 me-3"
                  onClick={e => closemodal(e)}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary px-5"
                  type="submit"
                  autoFocus={true}
                  onClick={e => handleConfirm(e)}
                >
                  Update
                </button>
              </div>
            </div>
          </form>

          <div></div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditTaskModal;
