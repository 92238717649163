import React, {useEffect, useState} from 'react';
import {AsYouType} from 'libphonenumber-js';
import {Account_Not_Found_Key, loginWithUserValidationCode, sendUserValidationCode,} from '../../services/authServices';
import logo from '../../assets/images/logo.svg';
import {Toast, ToastContainer} from 'react-bootstrap';
import {useHistory, useLocation} from 'react-router-dom';
import {getAuthData, removeUserData, updateAuthData} from '../../services/localServices';

export const Login = () => {
  const location = useLocation();
  const [disableSendCodeButton, setDisableSendCodeButton] = useState(true);
  const [disableSubmitCodeButton, setDisableSubmitCodeButton] = useState(true);
  const [isCodeSend, setIsCodeSend] = useState(false);
  const [isSendMagicClicked, setIsSendMagicClicked] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [email, setEmail] = useState('');
  const [isEmailValid, setEmailValid] = useState(true);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isResendButtonClicked, setIsResendButtonClicked] = useState(false);
  const [isCodeInValid, setIsCodeInValid] = useState(false);
  const history = useHistory();
  let userData = getAuthData();
  const [authData, authDataUpdate] = useState(JSON.parse(JSON.stringify(userData)));
  const [isEmailSignIn, setEmailSignIn] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paramValue = searchParams.get('mobileNumber');
    if (paramValue) {
      setMobileNumber(paramValue);
    }
  }, [location]);

  if (userData) {
    setTimeout(() => {
      userData.isUserNotFound = false;
      updateAuthData(userData);
    }, 1000);
  }
  const HandleMobileNumberChange = e => {
    const val = e.target.value.replace(/[a-zA-Z]/g, '');
    if (authData) authData.isUserNotFound = false;
    authDataUpdate(authData);
    let formatMinLength = 4;
    if (val[0] === '1') {
      formatMinLength = 6;
    }
    if (val.length > formatMinLength) {
      setMobileNumber(new AsYouType('US').input(val));
    } else {
      setMobileNumber(val);
    }
  };

  const HandleEmailChange = e => {
    const val = e.target.value;
    setEmail(val);
    const emailPattern = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const isValid = emailPattern.test(val);
    setEmailValid(isValid);
    setDisableSendCodeButton(!isValid);
  };

  const [magicCode, setMagicCode] = useState('');
  const HandleCodeChange = e => {
    setMagicCode(e.target.value);
    if (e.target.value.length === 6) {
      setDisableSubmitCodeButton(false);
    } else {
      setDisableSubmitCodeButton(true);
    }
  };

  useEffect(() => {
    ValidateNumber();
    removeUserData();
  }, [mobileNumber]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sessionStorage.getItem(Account_Not_Found_Key))
      sessionStorage.removeItem(Account_Not_Found_Key);
  }, []);

  function ValidateNumber() {
    if (mobileNumber.length !== 14) {
      setDisableSendCodeButton(true);
    } else if (isSendMagicClicked) {
    } else {
      setDisableSendCodeButton(false);
    }
  }

  async function SendMagicCode(isResendCode) {
    setIsSendMagicClicked(true);
    setIsCodeInValid(false);
    try {
      const mode = isEmailSignIn ? 'email' : 'sms';
      const contact = isEmailSignIn ? email : '+1' + mobileNumber.replace(/\D/g, '');
      const res = await sendUserValidationCode(contact, mode);
      if (res && res.hasOwnProperty('Id')) {
        setIsCodeSend(true);
        setMagicCode('');
        if (isResendCode) {
          setIsResendButtonClicked(true);
        }
      }
    } catch (err) {
      console.error(err);
      setIsSendMagicClicked(false);
      if (err.code === 'bad.connection') handleAccountNotFound();
    }
  }

  async function SubmitCode() {
    setIsSubmitButtonClicked(true);
    try {
      const code = document.getElementById('codeNumber').value.replace(/\D/g, '').trim();
      const mode = isEmailSignIn ? 'email' : 'sms';
      const contact = isEmailSignIn ? email : '+1' + mobileNumber.replace(/\D/g, '');
      if (code && code.length === 6) {
        await loginWithUserValidationCode(code, contact, mode);
      } else {
        setIsSubmitButtonClicked(false);
      }
    } catch (err) {
      setIsCodeInValid(true);
      setIsSubmitButtonClicked(false);
    }
  }

  async function handlePhoneFormSubmit(event) {
    event.preventDefault();
    await SendMagicCode(false);
  }

  async function handleMagicCodeSubmit(event) {
    event.preventDefault();
    await SubmitCode();
  }

  const back = () => {
    setIsCodeSend(false);
    setMobileNumber('');
    setIsSendMagicClicked(false);
    setIsResendButtonClicked(false);
  };

  const handleAccountNotFound = () => {
    sessionStorage.setItem(Account_Not_Found_Key, 'true');
    history.push('/un-authorize');
  };

  const handleSignInOptionChange = () => {
    setEmailSignIn(!isEmailSignIn);
    setDisableSendCodeButton(true);
    setEmailValid(true);
    setEmail('');
    setMobileNumber('');
  };


  return (
    <>
      {!isCodeSend ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <div className="login-wrapper">
            <div className="container">
              <div className="logo-wrap text-center">
                <img src={logo} alt="logo" />
              </div>
              <div className="login-footer d-flex flex-column justify-content-between">
                <form
                  onSubmit={handlePhoneFormSubmit}
                  method="post"
                  className="login-footer d-flex flex-column justify-content-between"
                >
                  <div>
                    <h1 className="heading1 login-heading text-center">Sign In</h1>
                    <div className="form-group">
                      {isEmailSignIn ? (
                          <>
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                placeholder="you@example.com"
                                value={email}
                                name="email"
                                className={`form-control ${!isEmailValid ? 'is-invalid' : ''}`}
                                onChange={HandleEmailChange}
                            />
                            {!isEmailValid && (
                                <p className="is-invalid-message">Please enter a valid email address.</p>
                            )}
                          </>
                      ) : (
                          <>
                            <label htmlFor="mobileNumber">Phone number</label>
                            <input
                                type="tel"
                                id="mobileNumber"
                                maxLength="14"
                                placeholder="(123) 456 7890"
                                value={mobileNumber}
                                name="phoneNumber"
                                className="form-control"
                                onChange={HandleMobileNumberChange}
                            />
                          </>
                      )}
                    </div>
                    {isEmailSignIn ? (
                        <p>
                          <a href="#" className="text-black text-decoration-underline" onClick={handleSignInOptionChange} style={{ fontSize: 'smaller' }}>
                            Sign in with Phone number
                          </a>
                        </p>
                    ) : (
                        <p>
                          <a href="#" className="text-black text-decoration-underline" onClick={handleSignInOptionChange} style={{ fontSize: 'smaller' }}>
                            Sign in with Email
                          </a>
                        </p>
                    )}
                    <p>
                      By continuing, you agree to the{' '}
                      <a
                        href="https://www.jobcall.com/terms"
                        className="text-black text-decoration-underline"
                      >
                        Terms of Service
                      </a>{' '}
                      &
                      <a
                        href="https://www.jobcall.com/privacy"
                        className="text-black text-decoration-underline"
                      >
                        {' '}
                        Privacy Policy
                      </a>
                      .
                    </p>
                  </div>
                  <button
                    className="btn btn-xl btn-primary btn-block"
                    type="button"
                    disabled={disableSendCodeButton || isSendMagicClicked}
                    onClick={() => SendMagicCode(false)}
                  >
                    Continue
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <header className="fixed-top header">
            <div className="login-wrapper">
              <div className="container">
                <div className="header-left">
                  <button className="btn btn-link-dark font-size-xl line-height-0" onClick={back}>
                    <i className="fe fe-arrow-left"></i>
                  </button>
                </div>
              </div>
            </div>
          </header>
          <div className="d-flex align-items-center justify-content-center vh-100">
            <div className="login-wrapper">
              <div className="container">
                <div className="logo-wrap text-center">
                  <img src={logo} alt="logo" />
                </div>
                <form
                  onSubmit={handleMagicCodeSubmit}
                  method="post"
                  className="login-footer d-flex flex-column justify-content-between"
                >
                  <div>
                    <h1 className="heading1 login-heading text-center">Enter Access Code</h1>
                    <div className="form-group mb-0">
                      <label htmlFor="codeNumber">Access Code</label>
                      <input
                        type="tel"
                        id="codeNumber"
                        maxLength="6"
                        placeholder="Enter 6 digit code"
                        name="code"
                        onChange={HandleCodeChange}
                        value={magicCode}
                        autoComplete="off"
                        className={`form-control ${isCodeInValid ? 'is-invalid' : ''}`}
                      />
                    </div>
                    {isCodeInValid && (
                      <p className="is-invalid-message">Access code incorrect. Please try again.</p>
                    )}
                    <button
                      type="button"
                      className="btn btn-link resend-btn"
                      onClick={() => SendMagicCode(true)}
                    >
                      Resend access code
                    </button>
                    <div className="position-relative">
                      <ToastContainer position="top-start">
                        <Toast
                          className="default-toast"
                          onClose={() => setIsResendButtonClicked(false)}
                          show={isResendButtonClicked}
                          delay={3000}
                          autohide
                        >
                          <Toast.Body>We sent another code to you.</Toast.Body>
                        </Toast>
                      </ToastContainer>
                    </div>
                  </div>
                  <button
                    className="btn btn-xl btn-primary btn-block"
                    type="button"
                    disabled={disableSubmitCodeButton || isSubmitButtonClicked}
                    onClick={() => SubmitCode()}
                  >
                    Sign In
                  </button>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
