import React from 'react';
import { Figure } from 'react-bootstrap';
import NoIssuesImg from '../../assets/images/no-issues.png';

export default function EmptyList({
  src = NoIssuesImg,
  className = 'empty-list',
  alt = '',
  caption = 'Nothing here',
}) {
  return (
    <Figure className={className}>
      <Figure.Image alt={alt} src={src} />
      <Figure.Caption>{caption}</Figure.Caption>
    </Figure>
  );
}
