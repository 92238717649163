import React, { useContext, useEffect, useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import {
  fetchProperty,
  fetchPropertyList,
  fetchPropertyUsers,
  updateProperty,
} from '../../services/dashBoardServices';
import { getSelectedProperty, updateSelectedProperty } from '../../services/localServices';
import AuthContext from '../../store/AuthContext';
import Loader from '../../components/Loader/Loader';
import PropertySwitcher from '../../components/PropertySwitcher/PropertySwitcher';
import UserSelect from '../../components/CallSettings/UserSelect';
import { REQUEST_STATUS } from '../../constants/loading';
import { DEVICES } from '../../constants/devices';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const formFields = [
  {
    name: 'Business Hours Contact',
    userKey: 'businessHoursUserId',
    contactKey: 'businessHoursContact',
  },
  {
    name: 'After Hours Contact',
    userKey: 'offHoursUserId',
    contactKey: 'offHoursContact',
  },
  {
    name: 'Alternate 1',
    userKey: 'alternateOneUserId',
    contactKey: 'alternateOneContact',
  },
  {
    name: 'Alternate 2',
    userKey: 'alternateTwoUserId',
    contactKey: 'alternateTwoContact',
  },
];

export const optionalKeys = ['alternateTwoUserId'];

const CallSettings = () => {
  const authContext = useContext(AuthContext);
  const [selectedProperty, setSelectedProperty] = useState({});
  const [propertyLists, setPropertyLists] = useState([]);
  const [propertyListsState, setPropertyListsState] = useState(REQUEST_STATUS.IDLE);
  const [propertyUsers, setPropertyUsers] = useState([]);
  const [propertyUsersState, setPropertyUsersState] = useState(REQUEST_STATUS.IDLE);
  const [properties, setProperties] = useState([]);
  const [propertiesState, setPropertiesState] = useState(REQUEST_STATUS.IDLE);
  const [propertiesForm, setPropertiesForm] = useState({});
  const [, deviceName] = useWindowDimensions();
  const [updateState, setUpdateState] = useState({
    messageIndex: null,
    showMessage: false,
    success: false,
  });
  const [submitForm, setSubmitForm] = useState({
    rowIndex: null,
    validated: false,
    submit: false,
  });

  useEffect(() => {
    //get property
    if (propertyListsState === REQUEST_STATUS.IDLE) {
      setPropertyListsState(REQUEST_STATUS.PENDING);
    }

    // Scroll to top when page loads
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    //get property list
    const loadAsync = async () => {
      setPropertyListsState(REQUEST_STATUS.LOADING);
      const userPar = authContext.user;
      const propList = await fetchPropertyList(userPar?.id);
      setPropertyLists(propList.propertyList);

      // Set selected Property
      let selectedProp = getSelectedProperty();
      if (!selectedProp?.propertyId) {
        selectedProp = propList.propertyList[0];
        updateSelectedProperty(selectedProp);
      }
      setSelectedProperty(selectedProp);

      setPropertyListsState(REQUEST_STATUS.SUCCESS);
      setPropertyUsersState(REQUEST_STATUS.PENDING);
    };

    if (propertyListsState === REQUEST_STATUS.PENDING) {
      loadAsync();
    }
  }, [propertyListsState]);

  useEffect(() => {
    const loadAsync = async () => {
      setPropertyUsersState(REQUEST_STATUS.LOADING);
      const propUsers = await fetchPropertyUsers(selectedProperty.propertyId);
      setPropertyUsers(propUsers?.userList);
      setPropertyUsersState(REQUEST_STATUS.SUCCESS);
      setPropertiesState(REQUEST_STATUS.PENDING);
    };

    if (propertyUsersState === REQUEST_STATUS.PENDING) {
      // set selected Property
      loadAsync();
    }
  }, [propertyUsersState]);

  useEffect(() => {
    const asyncLoad = async () => {
      setPropertiesState(REQUEST_STATUS.LOADING);
      const res = await getProperties();
      setProperties(res);
      setPropertiesState(REQUEST_STATUS.SUCCESS);
    };
    if (propertiesState === REQUEST_STATUS.PENDING) {
      asyncLoad();
    }
  }, [propertiesState]);

  useEffect(() => {
    const asyncLoad = async () => {
      await handleFormSubmit(submitForm.rowIndex);
      setSubmitForm({
        rowIndex: null,
        validated: false,
        submit: false,
      });
    };
    if (submitForm.validated) {
      asyncLoad();
    }
  }, [submitForm]);

  const handleSelectUser = (index, contactType, userId) => {
    const form = { ...propertiesForm };
    formFields.map(formField => {
      form[index][formField.userKey] = {
        ...hasError(form[index][formField.userKey].value, formField.userKey),
        value: form[index][formField.userKey].value,
      };
    });
    form[index][contactType] = {
      ...hasError(userId, contactType),
      value: userId,
    };

    setSubmitForm(prevState => ({
      ...prevState,
      rowIndex: index,
      validated: true,
      submit: false,
    }));

    setPropertiesForm(form);
  };

  const getProperties = async () => {
    const prop = await fetchProperty(selectedProperty?.propertyId);
    transformProperties(prop);
    return prop;
  };

  const getUserId = userId => {
    return propertyUsers.find(user => user.id === userId) ? userId : null;
  };

  const transformProperties = property => {
    const form = [];
    property.maintenanceContactList.map((weekdayContact, index) => {
      form[index] = {
        validated: false,
        businessHoursUserId: {
          value: getUserId(weekdayContact.businessHoursUserId),
          required: false,
          format: false,
        },
        offHoursUserId: {
          value: getUserId(weekdayContact.offHoursUserId),
          required: false,
          format: false,
        },
        alternateOneUserId: {
          value: getUserId(weekdayContact.alternateOneUserId),
          required: false,
          format: false,
        },
        alternateTwoUserId: {
          value: getUserId(weekdayContact.alternateTwoUserId),
          required: false,
          format: false,
        },
      };
      setPropertiesForm(form);
      return form;
    });
  };

  const handleFormSubmit = async index => {
    // Prevent initial execution
    if (propertiesForm && !propertiesForm[index]) {
      return;
    }
    let error = false;
    let diff = false;
    let propertyItem = {};

    // validate all keys and asign value to propertyItem
    formFields.map(formField => {
      if (
        propertiesForm[index][formField.userKey].required ||
        propertiesForm[index][formField.userKey].format
      ) {
        error = true;
      }
      if (
        propertiesForm[index][formField.userKey].value !==
        properties.maintenanceContactList[index][formField.userKey]
      ) {
        diff = true;
      }
      propertyItem[formField.userKey] = propertiesForm[index][formField.userKey].value;
    });

    // if no error or difference in form values
    if (error || !diff) {
      return;
    }

    // update property payload
    const updateProp = {
      propertyId: properties.maintenanceContactList[index].propertyId,
      maintenanceContactList: [
        {
          ...properties.maintenanceContactList[index],
          ...propertyItem,
        },
      ],
    };

    const propertiesRes = await updateProperty(updateProp);

    const success = propertiesRes?.maintenanceContactList?.length;

    setUpdateState({
      messageIndex: index,
      showMessage: true,
      success: !!success,
    });

    if (success) {
      setProperties(propertiesRes);
    }
    return success;
  };

  const hasError = (value, contractType) => {
    const err = {
      required: false,
      format: false,
    };
    if ((!value || value.length === 0) && !optionalKeys.includes(contractType)) {
      err.required = true;
    } else if (
      !propertyUsers.find(user => user.id === value) &&
      !optionalKeys.includes(contractType)
    ) {
      err.format = true;
    }
    return err;
  };

  const handleSelectProperty = selectedProp => {
    updateSelectedProperty(selectedProp);
    setSelectedProperty(selectedProp);
    setPropertyUsersState(REQUEST_STATUS.PENDING);
  };

  return (
    <>
      <Loader
        isLoading={
          propertyListsState === REQUEST_STATUS.LOADING ||
          propertiesState === REQUEST_STATUS.LOADING
        }
      />
      <PropertySwitcher
        subTitle="call settings"
        propLists={propertyLists}
        selectedProp={selectedProperty}
        onChange={handleSelectProperty}
      />
      <div className="container">
        {deviceName === DEVICES.MOBILE || deviceName === DEVICES.TABLET ? (
          <>
            {properties?.maintenanceContactList?.map((maintenanceContact, index) => (
              <Accordion className="custom-accordion custom-accordion-cs">
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <div className="cs-header d-flex flex-column">
                      <h4 className="cs-day">
                        {maintenanceContact.day}{' '}
                        {updateState.messageIndex === index && updateState.showMessage && (
                          <ToastContainer className="position-relative">
                            <Toast
                              className="custom-toast"
                              onClose={() => setUpdateState({ ...updateState, showMessage: false })}
                              show={updateState.showMessage}
                              delay={2000}
                              autohide
                            >
                              <Toast.Body>
                                <p className="mb-0 font-size-sm line-height36 text-center text-secondary">
                                  {updateState.success ? 'Saved!' : 'Error!'}
                                </p>
                              </Toast.Body>
                            </Toast>
                          </ToastContainer>
                        )}
                      </h4>
                      <div className="cs-hours d-flex">
                        <p className="title mb-0">Business Hours</p>
                        <p className="time mb-0">
                          {`${maintenanceContact.businessHoursFrom} - ${maintenanceContact.businessHoursTo}`}
                        </p>
                      </div>
                    </div>
                    <i className="toggle-arrow icon-chevron-right"></i>
                  </Accordion.Header>
                  <Accordion.Body>
                    {formFields.map(formField => (
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <p className="mb-0">{formField.name}</p>
                        <UserSelect
                          propertyUsers={propertyUsers}
                          handleSelectUser={handleSelectUser}
                          userId={propertiesForm[index][formField.userKey].value}
                          // contactNo={maintenanceContact[formField.contactKey]}
                          contactType={formField.userKey}
                          rowId={index}
                          showError={propertiesForm[index][formField.userKey].required}
                        />
                      </div>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))}
          </>
        ) : (
          <div className="card">
            <div className="table-responsive mb-0">
              <table className="table table-sm table-mob table-nowrap card-table head-light">
                <thead>
                  <tr>
                    <th>Day</th>
                    <th>Business Hours</th>
                    <th>Business Hours Contact</th>
                    <th>After Hours Contact</th>
                    <th>Alternate 1</th>
                    <th>Alternate 2</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {properties?.maintenanceContactList?.map((maintenanceContact, index) => {
                    //  messageIndex === maintenanceContact.id;
                    let animation = '';
                    if (updateState.messageIndex === index && updateState.showMessage) {
                      animation = updateState.success
                        ? 'border-blink--success 2.2s ease'
                        : 'border-blink--danger 2.2s ease';
                    } else {
                      animation = '';
                    }
                    return (
                      <tr
                        key={index}
                        style={{
                          animation: animation,
                        }}
                      >
                        <td className="align-top" data-label="Day">
                          <p className="mb-0 line-height36">{maintenanceContact.day}</p>
                        </td>
                        <td className="align-top" data-label="Business Hours">
                          <p className="mb-0 line-height36">
                            {maintenanceContact.isOpen && (
                              <span>
                                {maintenanceContact.businessHoursFrom} &nbsp;-&nbsp;{' '}
                                {maintenanceContact.businessHoursTo}
                              </span>
                            )}
                            {!maintenanceContact.isOpen && <span>Closed</span>}
                          </p>
                        </td>
                        {formFields.map((formField, id) => (
                          <td key={id} className="align-top" data-label="Business Hours Contact">
                            <UserSelect
                              propertyUsers={propertyUsers}
                              handleSelectUser={handleSelectUser}
                              userId={propertiesForm[index][formField.userKey].value}
                              contactNo={maintenanceContact[formField.contactKey]}
                              contactType={formField.userKey}
                              rowId={index}
                            />
                            {propertiesForm[index][formField.userKey].required && (
                              <p className="text-danger mb-0 mt-2">Contact required</p>
                            )}
                          </td>
                        ))}
                        <td className="align-top no-label">
                          <div className="save-message">
                            {updateState.messageIndex === index && updateState.showMessage && (
                              <ToastContainer className="position-relative">
                                <Toast
                                  className="custom-toast"
                                  onClose={() =>
                                    setUpdateState({ ...updateState, showMessage: false })
                                  }
                                  show={updateState.showMessage}
                                  delay={3000}
                                  autohide
                                >
                                  <Toast.Body>
                                    <p className="mb-0 font-size-sm line-height36 text-center text-secondary">
                                      {updateState.success ? 'Saved!' : 'Error!'}
                                    </p>
                                  </Toast.Body>
                                </Toast>
                              </ToastContainer>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                  {!properties?.maintenanceContactList?.length && (
                    <>
                      <tr>
                        <td colSpan="6" data-label="DAY">
                          <p className="mb-0 py-4 text-center">Nothing Here</p>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CallSettings;
