import parsePhoneNumber, { AsYouType } from 'libphonenumber-js';
import { USER_ROLES } from '../constants/roles';

export const preloadImages = imageList => {
  imageList.forEach(image => {
    let img = new Image();
    img.src = image;
    img.onload = () => {
      img = null;
    };
  });
};

export function getUSFormat(num) {
  const numberString = num.toString();
  return new AsYouType('US').input(numberString);
}

export function getDefaultFormat(num) {
  const numberString = num.toString();
  const phoneNumber = parsePhoneNumber(numberString, 'US');
  return phoneNumber?.nationalNumber;
}

export function toTitleCase(str) {
  return str.replace(/(\w)(\w*)/g, function (_, i, r) {
    return i.toUpperCase() + (r != null ? r : '');
  });
}

export function getSortByClassName(listSpec, sortColumn) {
  return listSpec.sortColumn !== sortColumn
    ? 'list-sort'
    : listSpec.sortDirection === 'ASC'
    ? 'list-sort list-sort-asc'
    : 'list-sort list-sort-desc';
}

export function getRoleLabel(userRole) {
  switch (userRole) {
    case USER_ROLES.SYSTEM_ADMIN:
      return 'System Admin';
    case USER_ROLES.PROPERTY_ADMIN:
      return 'Property Admin';
    case USER_ROLES.MAINTENANCE_ADMIN:
      return 'Maintenance Admin';
    default:
      return 'Unknown';
  }
}

export function getPreferredLangLabel(preferredLanguage) {
  switch (preferredLanguage) {
    case 'es':
      return 'Spanish';
    case 'en':
    default:
      return 'English';
  }
}

export function getCallEntryStatusLabel(callEntryStatus) {
  switch (callEntryStatus) {
    case 'pending':
      return 'Pending';
    case 'invalid_schedule':
      return 'Invalid Schedule';
    case 'answered':
      return 'Answered';
    case 'not_applicable':
      return 'Not Applicable';
    case 'not_answered':
    default:
      return 'Not Answered';
  }
}

export function getMonthLabel(month) {
  switch (month) {
    case '02':
      return 'February';
    case '03':
      return 'March';
    case '04':
      return 'April';
    case '05':
      return 'May';
    case '06':
      return 'June';
    case '07':
      return 'July';
    case '08':
      return 'Augeust';
    case '08':
      return 'September';
    case '10':
      return 'October';
    case '11':
      return 'November';
    case '12':
      return 'December';
    case '01':
    default:
      return 'January';
  }
}

export function getYearList() {
  const currentYear = new Date().getFullYear();
  const yearList = [];
  for (let i = currentYear - 1; i <= currentYear; i++) {
    yearList.push(i);
  }
  return yearList;
}
